<template>
<v-list>
  <div class="d-flex justify-center">
  <v-text-field solo class="input-border mx-5" placeholder="Search for user" prepend-inner-icon="mdi-magnify" v-model="search"></v-text-field>
<v-select class="custom-border-radius" solo small-chips :items="roles" v-model="selectedRoles" placeholder="Select Roles..." multiple></v-select>
  </div>
    <div style="width:100%" class="d-flex justify-end px-5">
    <v-btn :loading="loading" x-small @click="getAllUsers">Refresh <v-icon x-small>mdi-refresh</v-icon></v-btn>
  </div>
  <v-dialog v-if="hasRoleAdmin" v-model="showCreateUserDialog">
    <template #activator="{attrs,on}">
      <v-list-item v-on="on" v-bind="attrs" link class="offwhite mx-5 my-2">
        <v-list-item-icon>
          <v-icon>mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="font-weight-bold mr-5" style="font-size: 1.3rem;">
          Add new User
        </v-list-item-title>

      </v-list-item>
    </template>
    <v-sheet>
      <CreateUser @cancel="() => this.showCreateUserDialog = false" @save="() => this.showCreateUserDialog = false" role="ROLE_USER"/>
    </v-sheet>
  </v-dialog>
  <AdminUserListItem v-for="user of searchedUsers" :key="user.id" @deleteUser="deleteUser" :user="user" @updateUser="updateUser"/>

</v-list>
</template>

<script>
import {getUserListForAdmin} from "@/services/user.service";
import AdminUserListItem from "@/components/management/user/AdminUserListItem";
import CreateUser from "@/components/artist/CreateUser";

export default {
  name: "AdminUserList",
  components: {CreateUser, AdminUserListItem},
  data(){
    return{
      loading:false,
      users:null,
      selectedRoles:[],
      search:"",
      showCreateUserDialog:false
    }
  },
  computed:{
    roles(){
      if(!this.users) return [];
      let allRoles = [];

      // Iterate through each user
      this.users.forEach(user => {
        // Concatenate the roles of each user to the allRoles array
        allRoles = allRoles.concat(user.roles);
      });

      // Use Set to eliminate duplicates and spread syntax to convert back to array
      return [...new Set(allRoles)];
    },
    searchedUsers(){
      let result;
      if(this.users) {
        result = this.users.filter(x => x.name?.toLowerCase().includes(this.search.toLowerCase()) || x.fullName.toLowerCase().includes(this.search.toLowerCase()) || x.email.toLowerCase().includes(this.search.toLowerCase()) || x.roles.findIndex(role =>  role.toLowerCase().includes(this.search.toLowerCase())) !== -1);
        if(this.selectedRoles.length>0)result = result.filter( x=> x.roles.findIndex(role =>  this.selectedRoles.includes(role))!= -1);
        return result.sort((a, b) => {
          if(!a.name || !b.name) return 0;
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      }

      else return []
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    hasRoleAdmin(){
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
  },
  created(){
    this.getAllUsers();
  },
  methods:{
    deleteUser(id){
      if(this.users){
        this.users.splice(this.users.findIndex(x => x.id ===id),1);
      }
    },
    updateUser(user){
      this.$set(this.users,this.users.findIndex(x=> x.id === user.id),user);
      },
    async getAllUsers() {
      this.loading=true;
      this.users = await getUserListForAdmin();
      this.loading=false;
    }
  }
}
</script>

<style scoped>

</style>