<template>
<v-list height="300px" style="overflow-y: scroll">
  <PaymentMethod @select="setSelectedPaymentMethod" v-for="method of methods" :key="method.uid" :selected="selectedPaymentMethod === method.uid" :payment-method="method"/>
</v-list>
</template>

<script>
import PaymentMethod from "@/components/order/payment/PaymentMethod";
export default {
  name: "SelectPaymentMethod",
  components: {PaymentMethod},
  methods:{
    setSelectedPaymentMethod(value){
        this.selectedPaymentMethod=value;
        this.$emit("updatePaymentMethod",value);
    }
  },
  props:{
    methods:Array
  },
  data(){
    return{
      selectedPaymentMethod:null,
    }
  }
}
</script>

<style scoped>

</style>