<template>
<div>
<v-autocomplete
    class="input-border"
    v-model="newRoles"
    :items="allRoles"
    item-value="name"
    item-text="name"
    chips
    small-chips
    label="Select Roles"
    multiple
    :loading="loading"
    solo
    append-outer-icon="mdi-content-save"
    @click:append-outer="save"
></v-autocomplete>
</div>
</template>

<script>
import {getAllRoles} from "@/services/role.service";
import {updateUserRoles} from "@/services/user.service";

export default {
  name: "EditUserRoles",
  props:{
    selectedRoles:Array,
    userId:Number
  },
  computed:{
    selected:{
      get(){
        return this.selectedRoles
      },
      set(value){
        this.$emit("updateSingleRole",value)
      }
    }
  },
  data(){
    return{
      loading:false,
      newRoles:[],
      allRoles:[],

    }
  },
  created() {
    this.newRoles= this.selectedRoles;
    this.getAllRoles();
    },
  methods:{
    async save() {
      this.loading=true;
      let data = await updateUserRoles(this.userId,this.newRoles);
      this.$emit("updateUser",data);
      this.loading=false;
    },
    async getAllRoles() {
      this.allRoles = await getAllRoles();
    }
  }
}
</script>

<style scoped>

</style>