<template>
  <v-list>

    <v-list-item style="max-width: 300px">
      <v-list-item-title>Auf Website sichtbar</v-list-item-title>
      <v-list-item-icon><v-icon>mdi-eye</v-icon></v-list-item-icon>
    </v-list-item>
    <v-list-item style="max-width: 300px">
      <v-list-item-title>Nicht auf Website sichtbar</v-list-item-title>
      <v-list-item-icon><v-icon>mdi-eye-closed</v-icon></v-list-item-icon>
    </v-list-item>
    <v-text-field solo class="input-border mx-5" placeholder="Search for artist" prepend-inner-icon="mdi-magnify" v-model="search"></v-text-field>
    <div style="width:100%" class="d-flex justify-end px-5">
      <v-btn :loading="loading" x-small @click="getAllArtistsByPage">Refresh <v-icon x-small>mdi-refresh</v-icon></v-btn>
    </div>
    <v-dialog v-model="showCreateArtistDialog">
      <template #activator="{attrs,on}">
        <v-list-item v-on="on" v-bind="attrs" link class="offwhite mx-5 my-2">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold mr-5" style="font-size: 1.3rem;">
            Add new Artist
          </v-list-item-title>

        </v-list-item>
      </template>
      <v-sheet>
        <CreateUser @cancel="() => this.showCreateArtistDialog = false" @save="() => this.showCreateArtistDialog = false" role="ROLE_ARTIST"/>
      </v-sheet>
    </v-dialog>
    <ArtistListItem @deleteUser="deleteUser" v-for="artist of searchedArtists" :key="artist.id" :artist="artist" @updateArtist="updateArtist"/>
    <div class="d-flex justify-centerf">
      <!--<v-btn @click="loadMore" elevation="0" small class="mx-auto">load more</v-btn>
-->
    </div>

  </v-list>
</template>

<script>
import ArtistListItem from "@/components/artist/ArtistListItem";
import {getMyArtistsSimple} from "@/services/artist.service";
import CreateUser from "@/components/artist/CreateUser";
export default {
  name: "AdminArtistList",
  components: {CreateUser, ArtistListItem},
  data(){
    return{
      loading:false,
      artists:[
      ],
      search:"",
      page:0,
      showCreateArtistDialog:false
    }
  },
  computed:{
    searchedArtists(){
      return this.artists.filter(x => x.name?.toLowerCase().includes(this.search.toLowerCase()) || x.years.findIndex(year => year.toString() === this.search) != -1).sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    hasRoleAdmin(){
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
  },
  created() {
    this.getAllArtistsByPage();
  },
  methods:{
    deleteUser(id){
      if(this.artists){
        this.artists.splice(this.artists.findIndex(x => x.id ===id),1);
      }
    },
    updateArtist(artist){
      this.$set(this.artists,this.artists.indexOf(x => x.id === artist.id),artist);
    },
    loadMore(){
      this.page++;
      this.getAllArtistsByPage();
    },
    async getAllArtistsByPage() {
      this.loading=true;
      let response = await getMyArtistsSimple();
      //TODO not = on this.artists so the list gets expandable
      this.artists= response;
      this.loading=false;
    }
  }
}
</script>

<style scoped>

</style>