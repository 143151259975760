<template>
  <v-card style="max-width: 600px" class="mx-auto">
    <v-toolbar flat>
      <v-btn icon @click="$emit('toggleDialog',false)"><v-icon>mdi-close</v-icon></v-btn>
      <v-toolbar-title>Post on your feed</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
  <div style="width:auto;height:400px;position:relative" class="mx-auto">
  <v-img style="position:absolute;top:0;left:0;width:100%;height:100%;" width="100%" height="100%" :src="image? image :require('../../assets/Placeholder-Profile-819x1024.webp')">
  </v-img>
    <input type="file" style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:1;opacity: 0;" placeholder="File" @change="setImage"/>
  </div>
  <v-spacer style="height:10px"/>
    <v-textarea :rules="[v => (v && v.length <= 255)]" :counter="255" height="100px" v-model="feed.text" placeholder="Add Caption..."/>
    </v-card-text>
      <v-toolbar flat style="position: fixed;bottom:0; width:100%; max-width: 600px; border-top:1px solid var(--v-primary-lighten5)">
  <v-btn :loading="loading" block :disabled="!valid" @click="save">Save</v-btn>
</v-toolbar>
</v-card>
</template>

<script>
import CreateFeedItem from "@/models/CreateFeedItem";
import {compressImage} from "@/services/image.service";

export default {
  name: "CreateFeedItem",
  data(){
    return{
      loading:false,
      feed: new CreateFeedItem(),
      image:null,
    }
  },
  computed:{
    valid(){
      if(this.feed.text && this.feed.text.length<=255) return true;
      else return false
    },
  },
  methods:{
    async save() {
      this.loading=true;
     await this.$store.dispatch("feed/createItem",this.feed);
     this.loading=false;
     this.$emit("toggleDialog",false);
    },
    async setImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        let compressedFile = compressImage(files[0]).file;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.feed.file = event.target.result.split(',')[1];
        }
        reader.readAsDataURL(compressedFile);


    this.image = URL.createObjectURL(files[0]);
      }

  }
}
}
</script>

<style scoped>

</style>