<template>
  <v-chip-group column>
    <ProfessionItemUser @delete="deleteProfession" updateProfession="updateProfession" v-for="profession of professions" :key="profession.id" :profession="profession"/>
    <v-btn class="mx-auto my-5"  small @click="addNewProfession">add another Profession*</v-btn>

    <small class="mt-5">*Profession could be a Project you're working on/ a job</small>
  </v-chip-group>
</template>

<script>

import ProfessionItemUser from "@/components/user/ProfessionItemUser";
export default {
  name: "EditUserProfessions",
  components: {ProfessionItemUser},
  props:{
    professions: Array
  },
  computed:{
    professionsC:{
      get(){
        return this.professions;
      },set(value){
        this.$emit("update",value);
      }
    }
  },
  methods:{
    deleteProfession(profession){
      this.professionsC = this.professionsC.split(this.professionsC.findIndex(x => x.id ===profession.id),1);
    },
    updateProfession(profession){
      console.log("profession",profession);
      this.$set(this.professionsC,this.professionsC.findIndex(x => x.id ===profession.id),profession);
    },
    addNewProfession() {
      this.professionsC.push({id: null,role:null,company:{id:null,name:null}})
    }
  }
}
</script>

<style scoped>

</style>