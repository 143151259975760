<template>
  <v-card>
    <div class="pa-5">
      <v-btn large icon style="position:absolute; top:0;right:0" @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      <p v-for="navItem in $store.state.app.navItems" :key="navItem.title"><v-btn style="font-size: 2rem;" text  class="text-capitalize font-weight-bold text-center"  v-text="navItem.title" @click="gotTo(navItem.target)"></v-btn>
      </p>
      <div v-if="canAccessManagement" >
        <p><v-btn text class="text-capitalize" href="/management" ><v-icon>mdi-lock-open</v-icon>Conference Management</v-btn>
        </p>
          <p>
            <v-btn text class="text-capitalize" href="/shop">Shop</v-btn>
          </p>
      </div>
      <p>
        <v-btn text class="text-capitalize" href="https://berlin-dance-music-event.com" target="_blank">BDME Website</v-btn>
      </p>

    </div>


        <!--
              <v-list-item @click="$router.push('/welcome')">
                <v-list-item-title class="text-uppercase text-right">DEV - Welcome</v-list-item-title>
              </v-list-item><v-list-item @click="$router.push('/my/network')">
                <v-list-item-title class="text-uppercase text-right">DEV - Network</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push('/game')">
              <v-list-item-title class="text-uppercase text-right">DEV - Swipe</v-list-item-title>
            </v-list-item>
    -->


    <v-btn text plain large style="position: absolute;bottom:15px;right:15px" @click="handleLogout" class="text-right"><v-icon >mdi-logout</v-icon> Log out</v-btn>

  </v-card>
</template>

<script>
export default {
  name: "OffCanvasMenu",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    canAccessManagement() {
      return this.currentUser.roles.includes("ROLE_ADMIN") || this.currentUser.roles.includes("ROLE_PROMOTER") || this.currentUser.roles.includes("ROLE_CONF_MANAGER");
    },
  },
  methods:{
    handleLogout:function(){
      this.$store.dispatch('auth/logout');
      this.$emit("close");
      this.$router.push("/");
    },
    closeDialog(){
      this.$emit("close");
    },
    gotTo(target){
      this.$router.push("/"+target);
      this.$emit("close");
    },
  }
}
</script>

<style scoped>

</style>