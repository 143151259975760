import {getScoredUserlist} from "@/services/algorythm.service";
import store from "../store"

const initialState = {
    users:[],
    recommendations:{
        users:[],
        sortBy:"Recommended",
        canSortBy:[
            "Recommended",
            "A to Z",
            "Z to A"
        ]
    },
}

export const network = {
    namespaced: true,
    state: initialState,
    actions: {
        setUsers({ commit }, users) {
            commit("setUsers",users)
        },

        setRecommendedUsers({ commit }, users) {
            commit("setRecommendedUsers",users)
        },
        sortRecommendedBy({commit},value){
            commit("sortRecommendedBy",value);
        }

    },

    mutations: {

        setUsers(state, users){
            state.users=getScoredUserlist(users,store.state.auth.user);
        },
        setRecommendedUsers(state, users){
            state.recommendations.users=users;
        },
        sortRecommendedBy(state, value) {
            state.recommendations.sortBy=value;
        }


    }
};