<template>
  <div class="cart-item">
    <div class="product-info">
      <h3>{{ product.name }}</h3>
      <p class="price">Price: {{ product.price }} {{ product.currency }}</p>
      <p v-if="product.soldOut" class="sold-out">Sold Out</p>
      <p v-else class="availability">Availability: {{ product.availability }}</p>
      <p class="additional-info" v-html="product.additionalInformation"></p>
    </div>
    <div class="amount">
      <button @click="remove" class="btn">-</button>
      <span class="amount-value">{{ amount }}</span>
      <button @click="add" class="btn">+</button>
      <br/>
    <v-btn icon @click="deleteItem"> <v-icon>mdi-delete</v-icon></v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartItem",
  props: {
    cartResponse: null
  },
  data() {
    return {
    };
  },
  computed: {
    amount(){
      return this.cartResponse.amount;
    },
    product() {
      return this.cartResponse.product;
    },
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods: {
    deleteItem(){
      if(this.currentUser)this.$store.dispatch("cart/removeFromCart",this.cartResponse);
      else this.$store.dispatch("cart/removeFromCartAnonym",this.cartResponse);
    },
    add() {
      if(this.currentUser)this.$store.dispatch("cart/addOne",this.cartResponse);
      else this.$store.dispatch("cart/addOneAnonym",this.cartResponse);
    },
    remove() {
      if (this.amount > 1) {
        if(this.currentUser) this.$store.dispatch("cart/removeOne",this.cartResponse);
        else this.$store.dispatch("cart/removeOneAnonym",this.cartResponse);
      }
    }
  }
};
</script>

<style scoped>
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.product-info {
  flex: 1;
  margin-right: 20px;
}

h3 {
  margin: 0 0 10px;
  font-size: 18px;
}

.price {
  font-weight: bold;
  margin-bottom: 5px;
}

.sold-out {
  color: red;
  font-weight: bold;
  margin-bottom: 5px;
}

.availability {
  margin-bottom: 5px;
}

.additional-info {
  font-style: italic;
  color: #555;
}

.amount {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.btn {
  cursor: pointer;
  border: none;
  background-color: var(--v-primary-base);
  color: #fff;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 5px;
}

.btn:hover {
  background-color: #0056b3;
}

.amount-value {
  font-size: 18px;
}
</style>
