r<template>
  <div style="height:100%;position:relative" class=" pt-5">

    <div style="height:85%">
      <div style="height: 100%" class="overflow-y-auto px-10">
      <v-img class="ma-auto mb-5" :src="require('../../assets/Logo_b_transparent.png')" width="100px"></v-img>

      <ChooseTags v-if="page===1"/>
      <PersonalInformation v-else-if="page===2"/>
      <WorkInformation v-else-if="page===3"/>
      <WelcomeChangeImage v-else-if="page===4"/>
      <SocialInformation v-else-if="page===5"/>
        <WelcomeArtistGenre v-else-if="page===6"></WelcomeArtistGenre>
      <div v-else>Wrong</div>
      </div>
    </div>
  <div style="position:relative; height: 15%" class="pt-2 text-center">
<div>
    <v-btn color="accent" @click="() => {if(page>1) page--}" mr-2 outlined>Back</v-btn>
    <v-btn v-if="page===pages" color="success" class="ml-2"  @click="() => {this.$router.push('/lobby')}">Start Networking</v-btn>
    <v-btn color="accent" class="ml-2" v-else @click="() => {if(page<pages) page++}">Next</v-btn><br/><br/>
  <v-btn v-if="page<pages" @click="() => {if(page<pages) page++}" x-small plain text>Skip for now</v-btn>

</div>

  </div>
    <!--<small class="grey--text text-center"><span v-html="page"></span>/<span>{{pages}}</span></small><br/>-->

  </div>

</template>

<script>
import ChooseTags from "@/views/Welcome/ChooseTags";
import PersonalInformation from "@/views/Welcome/PersonalInformation";
import WorkInformation from "@/views/Welcome/WorkInformation";
import SocialInformation from "@/views/Welcome/SocialInformation";
import WelcomeChangeImage from "@/views/Welcome/WelcomeChangeImage";
import ApiService from "@/services/api.service";
import WelcomeArtistGenre from "@/views/Welcome/WelcomeArtistGenre";
export default {
  name: "WelcomeWrapper",
  components: {
    WelcomeArtistGenre,
    WelcomeChangeImage, SocialInformation, WorkInformation, PersonalInformation, ChooseTags},
  data(){
    return{

    }
  },
  computed:{
    page:{
      get() {
        return this.$store.state.app.welcome.page
      },
      set(value){
        this.$store.state.app.welcome.page=value;
      }
    },
    pages(){
      return this.$store.state.app.welcome.pages
    },
    currentUser(){
      return this.$store.state.auth.user;
    }

  },
  created(){
    this.$store.dispatch("app/checkIfArtist",this.currentUser);
    this.$store.dispatch("auth/disableFirstLogin");
    //this.getUpdatedCurrentUser();
  },
  methods:{
    getUpdatedCurrentUser(){
      let self=this;
      ApiService.get("user/get?id="+this.currentUser.id).then(response => {
        ApiService.post("user/update",response.data).then(response2 => {
          let user =JSON.parse(localStorage.getItem("user"));
          let merged = {...user, ...response2.data};
          this.$store.dispatch("auth/updateLocalUser",merged);
          self.$store.dispatch("loading/success");
        });
      });

    },
  }
}
</script>

<style scoped>

</style>