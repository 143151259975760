<template>
  <div style="width:auto;height:400px;position:relative" class="mx-auto custom-border-radius">
    <v-img class="custom-border-radius" style="position:absolute;top:0;left:0;width:100%;height:100%;" width="100%" height="100%" :src="image? image :require('@/assets/Placeholder-Profile-819x1024.webp')">
    </v-img>
    <input type="file" style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:1;opacity: 0;" placeholder="File" @change="setImage"/>
  </div>
</template>

<script>
import { compressImageCustom} from "@/services/image.service";

export default {
  name: "ImagePicker",
  props:{
    image:String
  },
  methods: {
    setImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        let compressedFile = compressImageCustom(files[0],0.1,300).file;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.$emit("updateImage", event.target.result.split(',')[1]);
        }
        reader.readAsDataURL(compressedFile);


         //return URL.createObjectURL(files[0]);
      }
    }
  }
}
</script>

<style scoped>

</style>