import {createFeed, getMyFeed} from "@/services/lobby.service";
export const feed = {
    namespaced: true,
    state: {
        items:[],
        page:0,
        endOfList:false
        },
    actions: {
        async createItem({commit},feed){
            let response = await createFeed(feed);
            if(response){
                commit("addItem",response);
            }
        },
        async loadFeedItems({commit}) {
            let response = await getMyFeed(0);
            commit("setFeedItems", response);
        },
        async loadCurrentPage({commit}){
            if(this.endOfList) return null;
            let response = await getMyFeed(this.page);
            commit("addItems",response);
        }
    },

    mutations: {
        addItem(state,item){
            state.items.push(item);
        },

        addItems(state,items){
          state.items.push(...items);
          if(items.length<10) state.endOfList = true;
          state.page++;
        },
        setFeedItems(state,items){
            if(items.length<10) state.endOfList = true;
            state.items = items;
            state.page=1;
        }

    }
};