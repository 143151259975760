<template>
<div class="rounded">
  <v-slide-group
      v-model="selectedUser"
      class="rounded mx-1"
      center-active
      show-arrows="desktop"
  >
    <v-slide-item
        class="rounded"
        v-for="user of users"
        :key="user.id"
        v-slot="{ toggle }"
    >
      <v-sheet class="mx-1" height="200"
               width="200">
        <v-hover
                 v-slot="{ hover }"

        >
      <UserSheetWall :hover="hover" :user="user" @click="toggle"></UserSheetWall>
        </v-hover>



      </v-sheet>
    </v-slide-item>
  </v-slide-group>
</div>
</template>

<script>
import UserSheetWall from "@/views/Lobby/UserSheetWall";
export default {
  name: "LobbySlider",
  components: {UserSheetWall},
  props:{
    users:Array
  },
  data(){
    return{
      selectedUser:null
    }
  }
}
</script>

<style scoped>

</style>