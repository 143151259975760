<template>
<v-dialog v-model="show">
  <template #activator="{attrs,on}">
    <v-btn color="accent" v-on="on" v-bind="attrs" :disabled="disabled" block class="mt-2 custom-border-radius">Create Account to buy</v-btn>
  </template>
  <v-card>
    <v-card-title>Register <v-spacer></v-spacer><v-btn icon><v-icon @click="show=false">mdi-close</v-icon></v-btn></v-card-title>
    <v-card-text>
      <RegisterForm address gender birth-date @updateUser="updateRequest">
        <template #submit>
          <v-btn @click="registerFromCart" color="accent" block class="mt-2 custom-border-radius">Create Account</v-btn>
        </template>
      </RegisterForm>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import RegisterForm from "@/components/auth/RegisterForm";
export default {
  name: "RegisterFromCart",
  components: {RegisterForm},
  props:{
    disabled:Boolean,
    cart:Array
  },
  data(){
    return{
      show:false,
      registerRequest:null
    }
  },
  methods:{
    updateRequest(user){
      this.registerRequest=user;
    },
    registerFromCart(){
      console.log("COmp",{signupRequest:this.registerRequest,cart:this.cart})
        this.$store.dispatch("auth/registerFromCart",{signupRequest:this.registerRequest,cart:this.cart});
    }
  }
}
</script>

<style scoped>

</style>