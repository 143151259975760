<template>
  <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
         style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f9f9f9; box-sizing: content-box;max-width: 100%;">
    <tbody>
    <tr>
      <td>
        <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
               role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
          <tbody>
          <tr>
            <td>
              <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                     role="presentation"
                     style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 670px; margin: 0 auto;"
                     width="670">
                <tbody>
                <tr>
                  <td class="column column-1" width="100%"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                    <table class="image_block block-1" width="100%" border="0" cellpadding="0"
                           cellspacing="0" role="presentation"
                           style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                      <tr>
                        <td class="pad"
                            style="padding-bottom:15px;padding-left:25px;padding-top:15px;width:100%;padding-right:0px;">
                          <div class="alignment" align="center" style="line-height:10px">
                            <div style="max-width: 167.5px;"><img
                                src="https://berlin-dance-music-event.com/wp-content/uploads/2024/01/Logo_b_transparent-black.png"
                                style="display: block; height: auto; border: 0; width: 100%;"
                                width="167.5" alt="Alternate text" title="Alternate text">
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
               role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff;">
          <tbody>
          <tr>
            <td>
              <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                     role="presentation"
                     style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; background-position: top center; color: #000000; width: 670px; margin: 0 auto;"
                     width="670">
                <tbody>
                <tr>
                  <td class="column column-1" width="50%"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                    <div class="spacer_block block-1"
                         style="height:30px;line-height:30px;font-size:1px;">&#8202;
                    </div>
                    <table class="text_block block-2" width="100%" border="0" cellpadding="0"
                           cellspacing="0" role="presentation"
                           style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                      <tr>
                        <td class="pad"
                            style="padding-bottom:10px;padding-left:25px;padding-right:10px;padding-top:10px;">
                          <div style="font-family: sans-serif">
                            <div class
                                 style="font-size: 12px; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; mso-line-height-alt: 14.399999999999999px; color: #61697a; line-height: 1.2;">
                              <p style="margin: 0; font-size: 14px; mso-line-height-alt: 16.8px;">
                                Hi {{name}},</p></div>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <table class="text_block block-3" width="100%" border="0" cellpadding="0"
                           cellspacing="0" role="presentation"
                           style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                      <tr>
                        <td class="pad"
                            style="padding-bottom:10px;padding-left:25px;padding-right:25px;padding-top:10px;">
                          <div style="font-family: sans-serif">
                            <div class
                                 style="font-size: 12px; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; mso-line-height-alt: 14.399999999999999px; color: #1f0b0b; line-height: 1.2;">
                              <p style="margin: 0; font-size: 34px; mso-line-height-alt: 40.8px;">
                                <strong>Welcome to the Berlin Dance Music Event
                                  {{year}}!</strong></p></div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td class="column column-2" width="50%"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"></td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
               role="presentation"
               style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; background-size: auto;">
          <tbody>
          <tr>
            <td>
              <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                     role="presentation"
                     style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; color: #000000; width: 670px; margin: 0 auto;"
                     width="670">
                <tbody>
                <tr>
                  <td class="column column-1" width="100%"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                    <table class="text_block block-1" width="100%" border="0" cellpadding="0"
                           cellspacing="0" role="presentation"
                           style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                      <tr>
                        <td class="pad"
                            style="padding-bottom:20px;padding-left:35px;padding-right:35px;padding-top:10px;">
                          <div style="font-family: sans-serif">
                            <div class
                                 style="font-size: 12px; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; mso-line-height-alt: 21.6px; color: #34495e; line-height: 1.8;">
                              <div style="margin: 0; font-size: 16px; mso-line-height-alt: 28.8px;">
                                <p>
                                  We are thrilled to have you join us.</p>
                                <p>
                                  Here is the access to your profile: <a
                                    href='https://members.berlin-dance-music-event.com/login'>https://members.berlin-dance-music-event.com</a>.

                                <p id="customText">
                                  <slot name="additionalContent">
                                    
                                  </slot>
                                </p>
                                <p> Additionally, you
                                  can utilize your profile in the community area to interact
                                  with participants, artists, and speakers.</p>
                              </div>
                              <div style='width:100%;height:50px;'></div>
                              <p style="margin: 0; font-size: 16px; mso-line-height-alt: 28.8px;">
                                email:&nbsp;{{email}}</p>
                              <p style="margin: 0; font-size: 16px; mso-line-height-alt: 21.6px;">
                                &nbsp;</p>
                              <p style="margin: 0;display:flex; align-items: center; font-size: 16px; mso-line-height-alt: 28.8px;">
                                password: <img style='margin-left:15px;'
                                               src="cid:attachment.png" alt="password"/></p>
                              <div style='width:100%;height:50px;'></div>
                              <p style="margin: 0; font-size: 16px; mso-line-height-alt: 28.8px;">
                                Your profile has been pre-filled with basic information.
                                Please feel free to update it as you see fit.
                                We look forward to having you at the Berlin Dance Music
                                Event {{year}}!</p>
                              <p style="margin: 0;margin-top:10px; font-size: 16px; mso-line-height-alt: 28.8px;">
                                Best Regards,</p>
                              <p style="margin: 0;margin-top:5px; font-size: 16px; mso-line-height-alt: 28.8px;">
                                Mika Heggemann</p>
                              <p style="margin: 0; font-size: 14px; mso-line-height-alt: 28.8px;">
                                Berlin Dance Music Event Team</p></div>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div class="spacer_block block-3"
                         style="height:30px;line-height:30px;font-size:1px;">&#8202;
                    </div>
                    <table class="text_block block-4" width="100%" border="0" cellpadding="0"
                           cellspacing="0" role="presentation"
                           style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                      <tr>
                        <td class="pad"
                            style="padding-bottom:10px;padding-left:35px;padding-right:35px;padding-top:5px;">
                          <div style="font-family: sans-serif">
                            <div class
                                 style="font-size: 12px; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; mso-line-height-alt: 18px; color: #34495e; line-height: 1.5;">
                              <p style="margin: 0; font-size: 12px; mso-line-height-alt: 18px;">
                                &nbsp;</p></div>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div class="spacer_block block-5"
                         style="height:30px;line-height:30px;font-size:1px;">&#8202;
                    </div>
                    <div class="spacer_block block-7"
                         style="height:20px;line-height:20px;font-size:1px;">&#8202;
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
               role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
          <tbody>
          <tr>
            <td>
              <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0"
                     role="presentation"
                     style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 670px; margin: 0 auto;"
                     width="670">
                <tbody>
                <tr>
                  <td class="column column-1" width="100%"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 10px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                    <table class="html_block block-1" width="100%" border="0" cellpadding="0"
                           cellspacing="0" role="presentation"
                           style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                      <tr>
                        <td class="pad">
                          <div style="font-family:Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;text-align:center;"
                               align="center">
                            <div style="height:30px;">&nbsp;</div>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <table class="image_block block-2" width="100%" border="0" cellpadding="0"
                           cellspacing="0" role="presentation"
                           style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                      <tr>
                        <td class="pad"
                            style="padding-bottom:15px;width:100%;padding-right:0px;padding-left:0px;">
                          <div class="alignment" align="center" style="line-height:10px">
                            <div style="max-width: 170px;"><a href="https://elephant-agency.de"
                                                              target="_blank"
                                                              style="outline:none"
                                                              tabindex="-1"><img
                                src="https://berlin-dance-music-event.com/wp-content/uploads/2024/01/Logo_b_transparent-black.png"
                                style="display: block; height: auto; border: 0; width: 100%;"
                                width="170"></a></div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "WelcomeUserEmail",
  props:{
    email:String,
    name:String,
    year:String
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: inherit !important;
}

#MessageViewBody a {
  color: inherit;
  text-decoration: none;
}

p {
  line-height: inherit
}

.desktop_hide, .desktop_hide table {
  mso-hide: all;
  display: none;
  max-height: 0px;
  overflow: hidden;
}

.image_block img + div {
  display: none;
}

@media (max-width: 690px) {
  .desktop_hide table.icons-inner {
    display: inline-block !important;
  }

  .icons-inner {
    text-align: center;
  }

  .icons-inner td {
    margin: 0 auto;
  }

  .image_block div.fullWidth {
    max-width: 100% !important;
  }

  .mobile_hide {
    display: none;
  }

  .row-content {
    width: 100% !important;
  }

  .stack .column {
    width: 100%;
    display: block;
  }

  .mobile_hide {
    min-height: 0;
    max-height: 0;
    max-width: 0;
    overflow: hidden;
    font-size: 0px;
  }

  .desktop_hide, .desktop_hide table {
    display: table !important;
    max-height: none !important;
  }

  .row-2 .column-2 .block-1.image_block td.pad {
    padding: 0 5px 0 0 !important;
  }

  .row-3 .column-1 .block-1.text_block td.pad {
    padding: 10px 30px 20px !important;
  }

  .row-3 .column-1 .block-4.text_block td.pad {
    padding: 5px 30px 10px !important;
  }
}
</style>