<template>
  <v-sheet color="secondary" class="pa-7">
    <h2 class="text-uppercase">Let's start growing your network!</h2>
    <p>Here are some Users that might be interesting for you!</p>
    <v-text-field v-model="searchQuery" prepend-inner-icon="mdi-magnify" placeholder="Search by Name, Role & Team" outlined dense></v-text-field>
    <div class="d-flex justify-space-between align-center">

      <div style="font-size: 0.7rem" class="text-truncate">{{filteredUsers.length}} <span v-if="searchQuery.length<=0">recommended Users</span> <span v-else>Users found</span></div>
      <v-select class="shrink" style="width:200px;font-size:0.7rem" dense hide-details solo v-model="$store.state.network.recommendations.sortBy" :items="$store.state.network.recommendations.canSortBy" flat>

        <template v-slot:prepend-inner>
          <v-icon small>mdi-swap-vertical</v-icon>
        </template>
        <template v-slot:selection="{item}">
          <span class="text-truncate">Sorted by: {{item}}</span>
        </template>
      </v-select>
    </div>
    <v-layout fill-height wrap>
      <v-flex grow class="pa-1" v-for="user of filteredUsers" :key="user.id" xs6 md2>
      <UserCard  :user="user" />
      </v-flex>
    </v-layout>
  </v-sheet>
</template>

<script>
import ApiService from "@/services/api.service";
//import UserSheetWall from "@/views/Lobby/UserSheetWall";
import UserCard from "@/views/Lobby/UserCard";

export default {
  name: "LobbyCards",
  components: {UserCard},
  data(){
    return{
      showFilter:false,
      users:[],
      filterByTags:[],
      filterByName:"",
      searchQuery:"",
      filterByCompanies:[],
      filterByRoles:[],
      tags:[]
    }
  },
  created() {
    this.getUsers();
    this.getAllTags();

  },
  computed:{
    companies(){
      return Array.from(new Set(this.users.map((user) => {

        return user.companyName;

      })));
    },roles(){
      return Array.from(new Set(this.users.map((user) => {

        return user.labelRole;

      })));
    },
    filteredUsers(){
      let users= this.users;
      if(this.filterByTags.length>0) users= users.filter(user => user.tags.some(tag => this.filterByTags.includes(tag)));
      if(this.filterByCompanies.length>0) users= users.filter(user => this.filterByCompanies.includes(user.companyName));
      if(this.filterByRoles.length>0) users= users.filter(user => this.filterByRoles.includes(user.labelRole));

      users= users.filter(user => (user.firstName+' '+user.lastName).includes(this.searchQuery) || user.companyName.includes(this.searchQuery) || user.labelRole.includes(this.searchQuery) || user.name.includes(this.searchQuery))

      if(this.$store.state.network.recommendations.sortBy==='Recommended') return users;
      else if(this.$store.state.network.recommendations.sortBy==='A to Z') {
        return users.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }
      else if(this.$store.state.network.recommendations.sortBy==='Z to A') {
        return users.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).reverse();
      }

      return users;

    },
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods:{
    getAllTags(){
      let self=this;
      ApiService.get("tags/get/all").then(response => {
        self.tags=response.data;
      })
    },
    addTagToFilter(id){
      if(this.filterByTags.includes(id)) this.filterByTags.splice(this.filterByTags.indexOf(id),1);
      else this.filterByTags.push(id)
    },
    getUsers(){
      let self=this;
      ApiService.get("user/getAll").then(response => {
        self.users=response.data;
        for(let user of self.users){
          user.bigDisplay=Math.random() < 0.1;
        }
      })
    },
    isPrime(n) {
      if (n<=1) return false;
      for (var i = 2; i <= n-1; i++)
        if (n % i == 0) return false;
      return true;
    }
  }
}
</script>

<style scoped>

.wrapper {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 600px) {
  .wrapper {
    display:grid;
    grid-template-columns: 1fr 1fr;
  }
}
.wrapper > div {
  width: 100%;
  height:100%;
  min-height: 200px;
  background-color: #E0E0E0;
  border: solid 1px white;
}

.background-name span{
  color:white!important;
}
</style>