export const app = {
    namespaced: true,
    state: {
        welcome:{
            page:1,
            pages:5,
            progress: 1,
            isArtist:false
        },
        navItems:[
    { title: 'Profile' , target: 'profile' },
    { title: 'Lobby', target: 'lobby' },
    { title: 'Messages', target: 'chat' },
    { title: 'Festival Program', target: 'events' },
    { title: 'My Timetable'  , target: 'favourites' },
    { title: 'Feed'  , target: 'feed' },
    //{ title: 'News' , target: 'news' },
],
        initialHistory:0
    },
    actions: {
        checkIfArtist({commit}, user) {
        console.log("Check")
            if(user.roles.includes("ROLE_ARTIST")){
                console.log("IsArtist");
                commit('isArtist');
            }
        },
    },
    mutations: {
        isArtist(state) {
            state.welcome.pages = 6;
            state.welcome.isArtist=true;
        },
    }
}