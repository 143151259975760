<template>
  <v-container style="position: relative" color="transparent" class="pa-7">
  <ContentLockedMessage :role="{id:0,name:'ROLE_TICKET_24'}"/>
    <v-chip-group  multiple v-model="filterByTags">
     <v-chip v-for="tag of tags" :value="tag.id" :key="tag.id">{{tag.name}}</v-chip>
   </v-chip-group>
    <div v-if="filterByTags.length>0 && tags.length>0">
      <small>Filtered by: </small><v-chip small outlined close v-for="tagItem of filterByTags" @click:close="removeFromFilter(tagItem)" :key="tagItem"><span v-html="displayTag(tagItem)"></span></v-chip>
    </div>
    <div class="d-flex justify-space-between align-center">

      <div style="font-size: 0.7rem" class="text-truncate">{{filteredUsers.length}} <span v-if="searchQuery.length<=0">Users</span> <span v-else>Users found</span></div>
      <v-select class="px-0 shrink transparent-input" style="width:200px;font-size:0.7rem" dense hide-details solo v-model="$store.state.network.recommendations.sortBy" :items="$store.state.network.recommendations.canSortBy" flat>

        <template v-slot:prepend-inner>
          <v-icon small>mdi-swap-vertical</v-icon>
        </template>
        <template v-slot:selection="{item}">
          <span class="text-truncate">Sorted by: {{item}}</span>
        </template>
      </v-select>
    </div>
    <div v-if="loading && filteredUsers.length<=0" class="rounded wrapper">
    <v-skeleton-loader v-for="index in 20" :key="index">

    </v-skeleton-loader>
    </div>
    <pagination-wrapper url="user/get/lobby/page/">
      <template #filter="{filter}">
        <v-text-field hint="You can also filter by tags by clicking below." v-model="filter.search" prepend-inner-icon="mdi-magnify" placeholder="Search by Name, Profession & Team" outlined dense></v-text-field>

      </template>
      <template #default="{items}">
        <p v-if="items.length<=0">No users found</p>
        <div class="wrapper">

            <UserSheetWall v-for="item of items" :key="item.id" @addTagToFilter="addTagToFilter" :index="item.id" :hover="false" :user="item"/>
        </div>
      </template>
    </pagination-wrapper>


  </v-container>
</template>

<script>
import ApiService from "@/services/api.service";
import UserSheetWall from "@/views/Lobby/UserSheetWall";
import ContentLockedMessage from "@/views/ViewPermission/ContentLockedMessage";
import PaginationWrapper from "@/components/PaginationWrapper.vue";

export default {
  name: "LobbyNew",
  components: {PaginationWrapper, ContentLockedMessage, UserSheetWall},
  props:{
    filter:Number,
  },
  data(){
    return{
      loading:false,
      showFilter:false,
      sortedBy:null,
      users:[],
      filterByTags:[],
      filterByName:"",
      filterByCompanies:[],
      filterByRoles:[],
      searchQuery:"",
      tags:[]
    }
  },
  watch:{
    filter(){
      this.addTagToFilter(Number(this.filter));
    },
  },
  created() {
    if(this.filter){
      this.addTagToFilter(Number(this.filter));
    }
    this.getUsers();
    this.getAllTags();

  },
  computed:{
    companies(){
      return Array.from(new Set(this.users.map((user) => {

        return user.companyName;

      })));
    },roles(){
      return Array.from(new Set(this.users.map((user) => {

        return user.labelRole;

      })));
    },
    filteredUsers(){
      let users= this.$store.state.network.users;
      //users=users.filter(user => user.id !== this.currentUser.id);
      //console.log("Filter "+users.filter(user => user.tags.some(tag => this.filterByTags.includes(tag))));
      if(this.filterByTags.length>0) users= users.filter(user => user.tags.some(tag => this.filterByTags.includes(tag)));
      if(this.filterByCompanies.length>0) users= users.filter(user => this.filterByCompanies.includes(user.companyName));
      if(this.filterByRoles.length>0) users= users.filter(user => this.filterByRoles.includes(user.labelRole));
      users= users.filter(user => (user.firstName+' '+user.lastName)?.includes(this.searchQuery) || user.professions?.some(p => p.role.includes(this.searchQuery)) || user.labelRole?.includes(this.searchQuery) || user.name?.includes(this.searchQuery))

      if(this.$store.state.network.recommendations.sortBy==='Recommended') {
        return users.sort(function(a, b) {
          return (a.algorithmScore < b.algorithmScore) ? 1 : (a.algorithmScore > b.algorithmScore) ? -1 : 0;
        });
      }
      else if(this.$store.state.network.recommendations.sortBy==='A to Z') {
        return users.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }
      else if(this.$store.state.network.recommendations.sortBy==='Z to A') {
        return users.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }).reverse();
      }


      return users;

    },
    currentUser(){
      return this.$store.state.auth.user;
    },

  },
  methods:{
    removeFromFilter(tagId){
     // console.log("Remove ",tagId)
    this.filterByTags.splice(this.filterByTags.indexOf(tagId), 1)
    },
    displayTag(tagItem){
      if(this.tags.length>0) {
        let result= this.tags.find(tag => {
          return tag.id===tagItem
        });
        if(result) return result.name
      } return null
    },
    getAllTags(){
      let self=this;
      ApiService.get("tags/get/all").then(response => {
        self.tags=response.data;
      })
    },
    addTagToFilter(id){
      if(this.filterByTags.includes(id)) this.filterByTags.splice(this.filterByTags.indexOf(id),1);
        else this.filterByTags.push(id)
    },
    getUsers(){
      let self=this;
      self.loading=true;
      ApiService.get("user/get/lobby").then(response => {
        self.users=response.data;
        for(let user of self.users){
          user.bigDisplay=Math.random() < 0.1;
        }
        this.$store.dispatch("network/setUsers",self.users);
        self.loading=false;
      });
    },
    isPrime(n) {
  if (n<=1) return false;
  for (var i = 2; i <= n-1; i++)
    if (n % i == 0) return false;
  return true;
}
  }
}
</script>

<style scoped>

.wrapper {
  display:grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 600px) {
  .wrapper {
    display:grid;
    grid-template-columns: 1fr 1fr;
  }
}
.wrapper > div {
  width: 100%;
  height:100%;
  min-height: 200px;
  background-color: #E0E0E0;
  border: solid 1px white;
}

.background-name span{
  color:white!important;
}

</style>