<template>
<div>
  <h3 class="mb-2">Address</h3>
  <v-text-field label="Postal Code" v-model="address.postalCode" solo></v-text-field>
  <v-text-field label="City" v-model="address.city" solo></v-text-field>
  <v-text-field label="Address" v-model="address.address" solo></v-text-field>
  <v-autocomplete
      label="Country"
      solo
      v-model="address.country"
      item-text="name"
      item-value="code"
      :items="countries"
  ></v-autocomplete>
  <v-divider class="mb-5"/>
</div>
</template>

<script>
import { countries } from 'iso-3166-1-alpha-2';
export default {
  name: "EditAddress",
  data(){
    return{
      address:{},
      countriesList: countries,
      countries: []
    }
  },
  watch:{
    address:{
      handler: function(val){
        this.$emit("updateAddress",val);
      },
      deep:true

    }
  },
  created() {
    this.countries = this.mapCountries();
  },
  methods: {
    mapCountries() {
      return Object.keys(this.countriesList).map(code => {
        return {
          name: this.countriesList[code],
          code: code
        };
      });
    }
  }
}
</script>

<style scoped>

</style>