<template>
  <div>

<v-form v-if="user">

  <div class="mx-auto my-5" style="height:300px;width:300px;max-width: 100%">
    <ImagePickerWithCropping @updateImage="updateImage" :image="userToEdit.profilePicture"/>
    </div>
  <v-btn block class="mb-5" small :download="userToEdit.name+'.png'" :href="'data:image/png;base64,'+userToEdit.profilePicture">Download Profile Picture</v-btn>

  <v-text-field solo v-model="userToEdit.firstName" label="Firstname" class="custom-border-radius"></v-text-field>
  <v-text-field solo v-model="userToEdit.lastName" label="Lastname" class="custom-border-radius"></v-text-field>
  <v-text-field solo v-model="userToEdit.email" label="Email" class="custom-border-radius"></v-text-field>
  <v-textarea solo v-model="userToEdit.bio" label="Bio" class="custom-border-radius"></v-textarea>
  <v-text-field solo v-model="userToEdit.name" label="Displayname" class="custom-border-radius"></v-text-field>
  <label>
    Genre
  <GenrePicker :genre-to-edit="genresToEdit" @update="updateGenres"/>
  </label>
  <label>
    Tags
    <UpdateTags :tags-prop="user.tags" @update="updateTags"/>
  </label>

  <EditUserProfessions :professions="userToEdit.professions" @update="updateProfessions"/>

  <v-text-field prepend-icon="mdi-soundcloud" outlined dense v-model="userToEdit.soundcloud" placeholder="..." prefix="https://soundcloud.com/"></v-text-field>
  <v-text-field prepend-icon="mdi-youtube" outlined dense v-model="userToEdit.youtube" placeholder="..." prefix="https://www.youtube.com/"></v-text-field>
  <v-text-field prepend-icon="mdi-spotify" outlined dense v-model="userToEdit.spotify" placeholder="..." prefix="https://open.spotify.com/artist/"></v-text-field>
  <v-text-field prepend-icon="mdi-linkedin" outlined dense v-model="userToEdit.linkedIn" placeholder="..." prefix="https://www.linkedin.com/in/"></v-text-field>
  <v-text-field prepend-icon="mdi-twitter" outlined dense v-model="userToEdit.twitter" placeholder="..." prefix="https://twitter.com/"></v-text-field>
  <v-text-field prepend-icon="mdi-instagram" outlined dense v-model="userToEdit.instagram" placeholder="..." prefix="https://www.instagram.com/"></v-text-field>

</v-form>
  <v-card-actions>
    <div style="width:100%" class="d-flex justify-center flex-wrap">
      <v-btn class="mb-2" block :loading="loading" @click="save">Save</v-btn>
    <v-btn  block :disabled="loading" @click="close">Cancel</v-btn>

    </div>
  </v-card-actions>
  </div>
</template>

<script>
import EditUser from "@/models/EditUser";
import UpdateTags from "@/components/user/updateTags";
import {getProfessionsByIds, getUserById} from "@/services/user.service";
import GenrePicker from "@/components/artist/GenrePicker";
import {editArtist} from "@/services/artist.service";
import ImagePickerWithCropping from "@/components/ImagePickerWithCropping";
import EditUserProfessions from "@/components/user/EditUserProfessions";


export default {
  name: "EditUserAdmin",
  components: {EditUserProfessions, ImagePickerWithCropping, GenrePicker, UpdateTags},
  props:{
    userId:Number
  },
  data(){
    return{
      user:null,
      userToEdit: new EditUser(),
      loading:false
    }
  },
  created() {
    this.getUser();
  },
  methods:{
    close(){
      this.$emit("close");
    },
    async getUser() {
      let user = await getUserById(this.userId);
      this.user = user;
      this.userToUserToEdit(this.user);
    },
    async save() {
      this.loading=true;
      let artist = await editArtist(this.userToEdit);
      this.loading=false;
      this.$emit("update",artist);
      this.close();
    },
    async userToUserToEdit(user) {
      this.userToEdit.name = user.name;
      this.userToEdit.bio = user.bio;
      this.userToEdit.userId = user.id;
      this.userToEdit.email = user.email;
      this.userToEdit.firstName = user.firstName;
      this.userToEdit.lastName = user.lastName;
      this.userToEdit.instagram = user.instagram;
      this.userToEdit.linkedIn = user.linkedIn;
      this.userToEdit.spotify = user.spotify;
      this.userToEdit.website = user.website;
      this.userToEdit.soundcloud = user.soundcloud;
      this.userToEdit.youtube = user.youtube;
      this.userToEdit.twitter = user.twitter;
      if (user.professions.length > 0) {
        let tmpProfessions = [];
        tmpProfessions = await getProfessionsByIds(user.professions.map(x => x.id));
        console.log("Profession tmp",tmpProfessions);

        this.userToEdit.professions = tmpProfessions;
      }
      else this.userToEdit.professions = [];
      this.userToEdit.tags = user.tags;
      this.userToEdit.genre = user.genre.map(x => x.id);
      if (user.profilePic) this.userToEdit.profilePicture = user.profilePic?.file;
      else this.userToEdit.profilePicture = null;
    },
    async updateGenres(genres) {
      this.userToEdit.genre=genres;
    },
    updateProfessions(professions){
      this.userToEdit.professions=professions;
    },
    updateTags(tags){
      this.userToEdit.tags=tags;
    },
    updateImage(image){
      this.userToEdit.profilePicture=image;
    }
  },
  computed:{
    genresToEdit(){
      if(this.user) return this.user.genre.map(x => x.id);
      else return null
    },
    userC:{
      get(){
        return this.user;
      },
      set(value){
        this.$emit("update",value);
      }
    }
  }
}
</script>

<style scoped>

</style>