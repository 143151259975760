import { render, staticRenderFns } from "./SingleEventSite.vue?vue&type=template&id=fd4c7704&scoped=true"
import script from "./SingleEventSite.vue?vue&type=script&lang=js"
export * from "./SingleEventSite.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd4c7704",
  null
  
)

export default component.exports