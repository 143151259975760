import AuthService from '../services/auth.service';
import {loadSettings} from "@/services/user.service";

const user = JSON.parse(localStorage.getItem('user'));
const setting = JSON.parse(localStorage.getItem('setting'));
const initialState = user
    ? { status: { loggedIn: true }, user, settings:setting }
    : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        updateLocalUser({commit},user){
            commit('updateLocalUser',user)
        },
        login({ commit }, user) {

            return AuthService.login(user).then(
                async user => {
                    commit('loginSuccess', user);
                    let setting = await loadSettings();
                    commit("setSettings", setting);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        loadSettings({commit}){
          let response = loadSettings();
          if(response){
              commit('setSettings',response)
          }
        },
        setSettings({commit},setting){
          commit('setSettings',setting);
        },
        loginCognito({ commit }, tokenObject) {

            return AuthService.loginCognito(tokenObject).then(
                async user => {
                    commit('loginSuccess', user);
                    let setting = await loadSettings();
                    commit("setSettings",setting);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                user => {
                    localStorage.setItem('user', JSON.stringify(user.data));
                    commit('loginSuccess', user.data);
                    return Promise.resolve(user);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        registerFromCart({commit},request){
            console.log("Reqquest,module",request);
          return AuthService.registerFromCart(request.signupRequest,request.cart).then(
              user => {
                  localStorage.setItem('user', JSON.stringify(user.data));
                  commit('loginSuccess', user.data);
                  return Promise.resolve(user);
              },
              error => {
                  commit('registerFailure');
                  return Promise.reject(error);
              });
        },
        disableFirstLogin({commit}){
            commit("setFirstLogin")
        }
    },
    mutations: {
        setFirstLogin(state){
            state.user.firstLogin = false;
            localStorage.setItem('user', JSON.stringify(state.user));
        },
        updateLocalUser(state,user){
            localStorage.setItem('user', JSON.stringify(user));
            state.user=user;
        },
        async loginSuccess(state, user) {

            state.status.loggedIn = true;
            state.user = user;
        },
        setSettings(state,setting){
            localStorage.setItem('setting', JSON.stringify(setting));
            state.settings=setting;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    }
};