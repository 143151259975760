<template>
  <v-container class="transparent py-10">
    <QRCodeReader :loading="loading" @code="setCode"/>
    <v-dialog v-model="success" id="ticket">
      <v-card>
        <v-card-text class="pa-5">
          <h3>Congrats! Your new Tickets has been added to your account.
          </h3>
          <SingleTicket/>
        </v-card-text>
        <v-card-actions>
          <v-btn small plain @click="() => this.success=false">Close</v-btn>
          <v-btn small text href="profile">Take me there</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import QRCodeReader from "@/components/QRCodeReader";
import SingleTicket from "@/components/ticket/SingleTicket";
import {scanTicket} from "@/services/ticket.service";
export default {
  name: "AddTicket",
  components: {
    SingleTicket,
    QRCodeReader

  },
  data(){
    return{
      ticketBarcode:null,
      loading:false,
      success:false,
    }
  },
  methods:{
    setCode(code){
      if(code.length>12)code = code.substring(0, 12);
      this.ticketBarcode=code;
      this.redeemTicket(code);
    },
    async redeemTicket(code) {
      if(code.length>12)code = code.substring(0, 12);
      console.log(code);
      this.loading = true;
      let response = await scanTicket(code);
        if(response) {
          console.log(response);
          this.loading = false
          this.success = true;
        }else{
          this.loading=false;
        }
    },

  }
}
</script>

<style scoped>

</style>