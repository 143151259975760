var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"mx-auto custom-border-radius",staticStyle:{"width":"auto","height":"100%","position":"relative"}},[_c('v-img',{staticClass:"custom-border-radius",staticStyle:{"position":"absolute","top":"0","left":"0","max-width":"100%","max-height":"100%"},attrs:{"width":"100%","height":"100%","src":_vm.image ? 'data:image/png;base64,'+_vm.image :require('@/assets/Placeholder-Profile-819x1024.webp')}}),_c('v-chip',{staticStyle:{"position":"absolute","left":"5px","bottom":"5px"}},[_vm._v(" Click to edit "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-camera")])],1),_c('input',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","z-index":"1","opacity":"0"},attrs:{"type":"file","placeholder":"File"},on:{"change":_vm.setImage}})],1),_c('v-dialog',{staticStyle:{"max-height":"100%"},attrs:{"content-class":"overflow-hidden","fullscreen":"","max-width":"100%"},model:{value:(_vm.hasImage),callback:function ($$v) {_vm.hasImage=$$v},expression:"hasImage"}},[_c('v-card',[_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.img=null}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.crop}},[_vm._v("Save")])],1),_c('v-chip',{staticStyle:{"position":"absolute","bottom":"50px","left":"50px","z-index":"99"}},[_vm._v("Use your Fingers or Mousewheel to zoom "),_c('v-icon',[_vm._v("mdi-gesture-pinch")])],1),_c('cropper',{ref:"cropper",staticClass:"upload-example-cropper",staticStyle:{"width":"100%","height":"100%","max-height":"90vh"},attrs:{"src":_vm.img,"stencil-props":{
		handlers: {},
		movable: false,
		scalable: false,
	},"stencil-size":{
		width: 280,
		height: 280
	},"image-restriction":"stencil","stencil-component":_vm.$options.components.Stencil}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }