<template>
  <div>
<v-list align="center" v-if="comments.length<=0">
  <v-list-item>
    <v-list-item-title>
      Be the first one leaving a comment
    </v-list-item-title>
  </v-list-item>
</v-list>
  <v-list v-else>
    <CommentListItem @deleteComment="deleteComment" v-for="comment of comments" :comment="comment" :key="comment.id"/>
  </v-list>
    <v-sheet class="pa-5" elevation="5" style="position: fixed;bottom:0;left:0;width:100%;height:100px;">
      <v-textarea @click:append="addComment" no-resize append-icon="mdi-send" rows="1" placeholder="add a comment..." @keydown.enter="addComment" v-model="newComment"></v-textarea>
    </v-sheet>
  </div>
</template>

<script>
import CommentListItem from "@/components/feed/CommentsListItem";
export default {
  name: "CommentsList",
  components: {CommentListItem},
  data(){
    return{
      newComment:null,
    }
  },
  props:{
    comments:Array
  },
  methods:{
    deleteComment(commentId){
      this.$emit("deleteComment",commentId);
    },
    addComment(){
      if(this.newComment && this.newComment!=" " && this.newComment!="")this.$emit("addComment",this.newComment);
      this.newComment=null;
    }
  }
}
</script>

<style scoped>

</style>