<template>
<v-list-item :dark="!event.conference" :class="{'primary': !event.conference, 'offwhite': event.conference}" link :href="'event/'+event.id" style="position: relative;" class="my-2 px-5 py-2 elevation-0 custom-border-radius">


  <v-list-item-content>
  <v-col cols="2" class="hidden-sm-and-down">
    <div class="d-flex justify-start">
      <v-img style="margin-right:-50px" class="hvr-front rounded-circle" max-width="75px" width="75px" height="75px" v-for="(artist) of artists" :key="artist.id" :src="artist.profilePicture?'data:image/png;base64,'+artist.profilePicture: require('../../assets/Placeholder-Profile-819x1024.webp')"/>
    </div>
  </v-col>
    <v-col>

      <v-list-item-title class="d-flex justify-space-between">
        <v-row>
          <v-col class="col-12 col-md-6" style="white-space: normal;">
            <v-row>
              <v-col class="col-12" >
                <span class="text-uppercase font-weight-bold" v-html="event.title"></span><br/>
                <small><span>/w </span><span class="text-uppercase" v-for="(artist,$index) of artists" :key="$index">{{artist.name}}<span v-if="$index<artists.length-1">, </span></span></small>
              </v-col>
            </v-row>
            <v-row>



            </v-row>
          </v-col>
        </v-row>
      </v-list-item-title>
      <v-list-item-subtitle class="d-flex justify-space-between">
        <v-row>
          <v-col cols="6" class="col-12 col-md-6">
            <div class="d-flex align-center"><v-icon small>mdi-map-marker</v-icon> <span v-html="event.venue?.name"></span></div>
          </v-col>
          <v-col v-if="event.savedByIds.length>0" cols="4" class="col-12 col-md-4">
        <span class="d-flex flex-nowrap">
<div v-if="currentUser" style="z-index:99">
    <UserPopover tile v-for="(user,index) in event.savedByIds.slice(0,9)" :key="user.id" :user="user" :index=index>

      </UserPopover>
          <v-menu
              v-if="event.savedByIds.length>9"
              bottom
              max-height="200px"
              origin="center center"
              transition="slide-y-transition"
          >
      <template v-slot:activator="{ on, attrs }">
    <v-btn v-bind="attrs" v-on="on"  class="align-self-center pl-0" plain text x-small>+{{event.savedByIds.length-9}} more</v-btn>
      </template>
            <v-list>
              <UserPopover v-for="user of event.savedByIds" :key="user.id" :user="user" index="0"></UserPopover>
            </v-list>
          </v-menu>
  </div>
          <div v-else>
            <span v-html="event.savedByIds.length"></span> <span>people participating</span>
          </div>
        </span>
          </v-col>
          <v-col v-else cols="4" class="col-12 col-md-4 hidden-sm-and-down">
          </v-col>

              <div class="px-5 py-2" style="position: absolute;bottom:0;right:0;font-weight: lighter"><small v-html="start"></small> <small class="hidden-md-and-ups"></small></div>


        </v-row>
      </v-list-item-subtitle>

    </v-col>
    </v-list-item-content>
  <v-list-item-icon>
    <span v-if="!currentUser">
        <v-btn @click.prevent="loginToSaveFavourites" icon><v-icon>mdi-bookmark-outline</v-icon></v-btn>
     </span>
    <v-btn v-else-if="currentUser.favourites?.includes(event.id)" icon @click.prevent="removeFavourite(event.id)"><v-icon>mdi-bookmark</v-icon></v-btn>
    <v-btn v-else icon @click.prevent="addToFavourites(event.id)" ><v-icon>mdi-bookmark-outline</v-icon></v-btn>
  </v-list-item-icon>

</v-list-item>


</template>

<script>
import ApiService from "@/services/api.service";
import UserPopover from "@/views/Lobby/UserPopover";
import moment from "moment";
import Notification from "@/models/notification";
import Event from "@/models/event";
export default {
  name: "EventItem",
  components: {UserPopover},
  props:{
    artists: Array,
    event:Event
  },
  data(){
    return{
      backgroundOpacity:0.12
    }
  },
  computed:{
    start(){
      moment.locale('en');
      return moment(this.event.dateTime).format('LLLL');
    },
    eventDay(){
      moment.locale("en");
      return moment(this.event.dateTime).format('dddd');
    },
    currentUser:{
      get(){
        return this.$store.state.auth.user;

      },
      set(data){
        this.$store.state.auth.user=data;
      }
      }
  },
  watch:{
    '$vuetify.theme.dark'(){
      if(this.$vuetify.theme.dark) this.backgroundOpacity=0.02;
      else this.backgroundOpacity=0.12;
    }
  },
  methods:{
    addToFavourites(id){
      if(!this.currentUser.favourites) this.currentUser.favourites=new Array();
      if(!this.currentUser.favourites?.includes(id)) {
        this.currentUser.favourites.push(id);
        this.updateUser();

        ApiService.get("event/favourites/add/"+this.event.id);
        this.$emit("addToFavourites",this.currentUser.id);
        //this.event.savedByIds.push(this.currentUser);

      }
    },
    removeFavourite(id){
      if(this.currentUser.favourites?.includes(id)){
        this.currentUser.favourites.splice(this.currentUser.favourites.indexOf(id),1);
        this.updateUser();
        ApiService.get("event/favourites/remove/"+this.event.id);
        this.$emit("removeFromFavourites",this.currentUser.id);
        //this.event.savedByIds.splice(this.event.savedByIds.indexOf(x=> x.id=this.currentUser.is),1);
      }
    },
    loginToSaveFavourites(){
        this.$store.dispatch("notificationsStore/add",new Notification("warning", "Login to save Favourites.", false,null,"/login","Login now"))
    },
    updateUser(){
      let self=this;
      ApiService.post("user/update",this.currentUser).then(response => {
        console.log(this.currentUser)
        let user =JSON.parse(localStorage.getItem("user"));
        let merged = {...user, ...response.data};
        console.log(merged)
        this.$store.dispatch("auth/updateLocalUser",merged);
        self.$store.dispatch("loading/success");
      });
    },
  }
}
</script>

<style scoped>
.event-box-overlay{
  background-position: 0,50%;
  background-image: url("../../assets/wellen-2.webp");
  background-size: cover;
  opacity: 0.12;
}
.event-box {
  border-style: solid;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-width: 2px 2px 2px 2px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-color: #BCBCBC;
  border-top-color: rgb(188, 188, 188);
  border-right-color: rgb(188, 188, 188);
  border-bottom-color: rgb(188, 188, 188);
  border-left-color: rgb(188, 188, 188);
}
</style>