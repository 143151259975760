export default class FeedItem{

    id;
    text;
    file;
    likes;
    tags;
    comments;
    autor;
    creationDate;

    constructor(){
        this.id=null;
        this.text=null;
        this.file=null;
        this.likes=[];
        this.tags=[];
        this.comments=[];
        this.autor=null;
        this.creationDate=null;
}
}
