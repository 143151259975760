export default class User {


    constructor(name, email, password,image,firstName,lastName) {
        this.name = name;
        this.email = email;
        this.password = password;
        this.image=image;
        this.firstName=firstName;
        this.lastName=lastName;
    }
}
