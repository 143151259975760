<template>
<v-overlay class="custom-overlay" opacity="0.9" absolute z-index="20" v-model="notHasRole">
  <v-card dark max-width="500px" style="border:1.5px solid white">
    <v-card-title>Kein Zugriff</v-card-title>
    <v-card-text>Du besitzt leider keinen Zugriff zu diesem Inhalt. Verbinde dein gültiges Ticket mit deinem Account, um Zugriff zu erhalten.</v-card-text>
    <v-card-actions>
      <v-btn text @click="$router.back()">Abbrechen</v-btn><v-btn>Jetzt Ticket verbinden</v-btn>
    </v-card-actions>
  </v-card>
</v-overlay>
</template>

<script>
import Role from "@/models/role";

export default {
  name: "ContentLockedMessage",
  props:{
    role: Role
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    notHasRole(){
      let roles = this.currentUser.roles;
      if(roles.some( x => x.name === this.role.name)) return true;
      else return false;
    }
  }
}
</script>

<style scoped>

</style>