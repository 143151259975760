import ApiService from "../services/api.service"

export function calculateScore(user,currentUser){

    let tagMatches=user.tags.filter(v => currentUser.tags.includes(v)).length;

    let companyMatches=user.professions.filter(v=> currentUser.professions.some(p => p.companyId === v.companyId)).length;

    let roleMatches = user.professions.filter(v => currentUser.professions.some(p=> p.role === v.role)).length;

    let result= tagMatches*2+companyMatches*4+roleMatches;
    return result;

}
export function getScoredUserlist(users,currentUser){
    for(let user of users){
        user.algorithmScore=calculateScore(user,currentUser);
    }

    return users;
}

export function buildNetwork(currentUser){
    currentUser.network=[2,3,4,5,6,7,8,9]
    let result={nodes: [],
        edges: [],
        };
    result.nodes.push({id: currentUser.id,label:currentUser.name,shape:'circularImage',image: currentUser.profilePic?'data:image/png;base64,'+currentUser.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png',fixed:true,size:50});

    for(let uid of currentUser.network) {
        result.edges.push({from: currentUser.id, to: uid});


        ApiService.get("user/get?id="+uid).then(response =>{
            let user = response.data;
            result.nodes.push({id: user.id,label:user.name,shape:'circularImage',image: user.profilePic?'data:image/png;base64,'+user.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png',group:user.companyName});
            user.network=[2,5,3,6];
          for(let userId of user.network){
           /**     if(!result.nodes.some(x => x.id === user.id)){
              ApiService.get("user/get?id="+userId).then(response => {
                      result.nodes.push({id: response.data.id,label:response.data.name,shape:'image',image: response.data.profilePic?'data:image/png;base64,'+response.data.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png',opacity:0.8});

                  })
                }
            */
                if(!result.edges.some(x => x.from===userId && x.to===user.id) && userId!=user.id) result.edges.push({from: user.id, to: userId, hidden:true});
            }
        });

    }
    return result;
}