import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth.module';
import {loading} from './loading.module';
import {chat} from './chat.module';
import {notificationsStore} from "@/store/notifications.module";
import {events} from "@/store/events.module";
import {network} from "@/store/network.module";
import {feed} from "@/store/feed.module";
import {app} from "./app.module";
import {cart} from "@/store/cart.store";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        feed,
        cart,
        auth,
        loading,
        chat,
        notificationsStore,
        events,
        network,
        app
    }
});