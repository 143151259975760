<template>
<v-sheet class="pb-2">
  <v-sheet dark>
 <v-layout class="pt-5">
   <v-flex xs5 class="d-flex justify-center">
      <v-avatar style="transform:translateY(1.5em)" tile size="130">
        <v-img v-if="user" class="rounded" :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"></v-img>

      </v-avatar>
   </v-flex>
   <v-flex xs7 class="">
     <v-sheet dark color="transparent" height="100%" class="pt-4" style="max-width:100%;position:relative">
       <v-skeleton-loader class="mb-2 mt-1" v-if="$store.state.loading.loadingState" type="heading"></v-skeleton-loader>
       <span  v-else   class="font-weight-bold" style="font-size:1.5rem">

       <span v-if="user.firstName && user.lastName" v-text="user.firstName+' '+user.lastName"></span>
       <span v-else-if="user.name" v-text="user.name"></span>
       <span v-else>Unnamed</span>
      </span><br v-if="!$store.state.loading.loadingState"/>
       <span v-if="$store.state.loading.loadingState">
         <v-skeleton-loader type="text" class="mt-3" width="200px"></v-skeleton-loader><br/>
       <v-skeleton-loader type="button" class="button-loader" style="border-radius: 10px 10px 0 0;position:absolute; left:0;bottom:0"></v-skeleton-loader>
    </span>
       <span v-else>
       <span v-if="professions[0] "><span v-html="professions[0].role"></span>@<span v-if="professions[0].company" v-html="professions[0].company.name"></span></span><br/>
       <v-btn color="accent" elevation="0" @click="openChat" style="border-radius: 10px 10px 0 0;position:absolute; left:0;bottom:0" small class="text-center"><v-icon small class="mr-1">mdi-message</v-icon>Chat with me</v-btn>
     </span>
     </v-sheet>
   </v-flex>
 </v-layout>
  </v-sheet>
  <v-sheet color="whitevariant"  class="pt-5 pb-3">
    <h3 class="pt-5 px-5">Information</h3>
    <v-skeleton-loader style="min-height: 100px" class="py-5 px-5" v-if="$store.state.loading.loadingState" type="paragraph"></v-skeleton-loader>

    <p v-else style="min-height: 100px" class="py-5 px-5">
      <span v-if="user.bio"  v-text="user.bio"></span>
      <span v-else>Nothing here...</span>
    </p>
    <v-sheet v-if="$store.state.loading.loadingState" color="transparent" class="px-5 py-2 my-2 d-flex flex-nowrap justify-start">
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>
      <v-skeleton-loader class="little-avatar-loader" type="avatar"></v-skeleton-loader>


    </v-sheet>
    <v-sheet v-else color="transparent" class="px-5 py-2 my-2 d-flex justify-start">


      <v-btn v-if="user.spotify" :href="'https://open.spotify.com/artist/'+user.spotify" target="_blank" icon color="primary">
        <v-icon>mdi-spotify</v-icon>
      </v-btn>
      <v-btn v-if="user.soundcloud" :href="'https://soundcloud.com/'+user.soundcloud" target="_blank" color="primary" icon>

        <v-icon>mdi-soundcloud</v-icon>
      </v-btn>
      <v-btn v-if="user.instagram" :href="'https://www.instagram.com/'+user.instagram" target="_blank" color="primary" icon>
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn v-if="user.youtube" :href="'https://www.youtube.com/'+user.youtube" target="_blank" color="primary" icon>
        <v-icon>mdi-youtube</v-icon>
      </v-btn>
      <v-btn v-if="user.linkedIn" :href="'https://www.linkedin.com/in/'+user.linkedIn" target="_blank" color="primary" icon>
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <v-btn v-if="user.twitter" :href="'https://twitter.com/'+user.twitter" target="_blank" color="primary" icon>
        <v-icon>mdi-twitter</v-icon>
      </v-btn>

    </v-sheet>
  </v-sheet>



  <div class="px-5 py-5 d-flex justify-start">
    <div class="d-flex justify-start flex-nowrap" v-if="$store.state.loading.loadingState">
      <v-skeleton-loader class="mr-1"  type="chip"></v-skeleton-loader>
      <v-skeleton-loader class="mr-1" type="chip"></v-skeleton-loader>
      <v-skeleton-loader class="mr-1" type="chip"></v-skeleton-loader>
    </div>
      <v-chip-group v-else column>
      <v-chip @click="$router.push('/lobby/'+tag.id)" v-for="tag of tags" :key="tag.id">
        {{tag.name}}
      </v-chip>
    </v-chip-group>
  </div>

  <v-sheet class="my-5 mx-5">
    <h3>Professions</h3>
    <v-skeleton-loader v-if="$store.state.loading.loadingState" width="200px" type="list-item-two-line@3"></v-skeleton-loader>

    <v-list v-else>
      <v-list-item two-line v-for="profession of professions" :key="profession.id">
        <v-list-item-content v-if="profession.role && profession.company">
        <v-list-item-title>
          <span>{{profession.role}}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <span>@<span v-if="profession.company">{{profession.company.name}}</span></span>
        </v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>
    </v-list>
  </v-sheet>

  <div class="my-5 mx-5" v-if="usersWithSameCompany.items.length>0">
  <h3 >People working with {{user.firstName}}</h3>
  <div class="rounded wrapper wcol-3 mt-5">
    <v-hover v-for="(user,index) in usersWithSameCompany.items" :key="user.id"
             v-slot="{ hover }"

    >
      <UserSheetWall size=0.5 :index=index :hover="hover" :user="user"/>
    </v-hover>
  </div>
      <div class="d-flex justify-center">
      <v-btn color="grey" small class="my-2" @click="loadMoreCompany">Load more <v-icon>mdi-arrow-down</v-icon></v-btn>
      </div>
  </div>
  <div class="my-5 mx-5" v-if="usersWithSameRole.items.length>0">
  <h3>Other <span v-for="(profession,index) of currentUser.professions" :key="profession.id"><span v-html="profession.role"></span><span v-if="index<currentUser.professions.length-1">/</span> </span></h3>
  <div class="rounded wrapper wcol-3 mt-5">
    <v-hover v-for="(user,index) in usersWithSameRole.items" :key="user.id"
             v-slot="{ hover }"

    >
      <UserSheetWall size=0.5 :index=index :hover="hover" :user="user"/>
    </v-hover>
  </div>
    <div class="d-flex justify-center">
      <v-btn color="grey" small class="my-2" @click="loadMoreRole">Load more <v-icon>mdi-arrow-down</v-icon></v-btn>
    </div>
  </div>
  <div style="max-width: 500px">
    <h3>Posts</h3>
    <FeedOfUser :user-id="id"/>
  </div>
</v-sheet>


</template>

<script>
import ApiService from "../../services/api.service";
import UserSheetWall from "@/views/Lobby/UserSheetWall";
import FeedOfUser from "@/components/feed/FeedOfUser";

export default {
  name: "UserDetails",
  data(){
    return{
      user:null,
      professions:[],
      tags:[],

      usersWithSameRole:{
        items:[],
        count:3
      },
      usersWithSameCompany:{
        items:[],
        count:3
      }
    }
  },
  beforeRouteUpdate(to) {
    // called when the route that renders this component has changed, but this component is reused in the new route.
    // For example, given a route with params `/users/:id`, when we navigate between `/users/1` and `/users/2`,
    // the same `UserDetails` component instance will be reused, and this hook will be called when that happens.
    // Because the component is mounted while this happens, the navigation guard has access to `this` component instance.


    this.getUser();
    this.getProfessions(to.params.id);
    this.$emit("scrollTop");

  },
  computed:{
  isArtist(){
    return this.currentUser.roles.includes("ROLE_ARTIST")
  },
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  components:{FeedOfUser, UserSheetWall},
  props:{
    id:String
  },
  created() {
  this.getUser();
    this.getProfessions(this.id);
    },
  methods:{
    getProfessions(userId){
      let self=this;
      ApiService.get("company/find/from/"+userId).then(response => {
        self.professions=response.data;
        //console.log("Profession",response.data)
      })
    },
    openChat(){
      this.openNewChat();
      this.$router.push('/chat/open/'+this.user.id)
    },
    openNewChat(){
      if(this.currentUser.currentChatPartners && !this.currentUser.currentChatPartners.includes(this.user.id)){
        this.currentUser.currentChatPartners.push(this.user.id);

        this.updateUser();
      }

    },
    updateUser(){
      let self=this;
      ApiService.post("user/update",this.currentUser).then(response => {
        let user =JSON.parse(localStorage.getItem("user"));
        let merged = {...user, ...response.data};
        this.$store.dispatch("auth/updateLocalUser",merged);
        self.$store.dispatch("loading/success");
      });
    },
    loadMoreRole(){
      this.usersWithSameRole.count+=6;
      this.getAllWithSameLabelRole();
    },loadMoreCompany(){
      this.usersWithSameCompany.count+=6;
      this.getAllWithSameCompany();
    },
    getAllWithSameLabelRole(){
      let self=this;
      ApiService.get("user/getAllByLabelRole/"+self.user.id+"/"+this.usersWithSameRole.count).then(response => {
          self.usersWithSameRole.items=response.data;
      });
      },
    getAllWithSameCompany(){
      let self=this;
      ApiService.get("user/getAllWithSameCompany/"+self.user.id+"/"+this.usersWithSameCompany.count).then(response => {
        self.usersWithSameCompany.items=response.data;
      });
    },
    getUserTags(){
      let self=this;
      console.log(self.user.tags)
      ApiService.post("tags/get/custom",{ids:self.user.tags}).then(response => {
        self.tags=response.data;
        console.log(response.data)
        self.$store.dispatch("loading/success");
      })
    },
    getUser(){
      let self=this;
      self.$store.dispatch("loading/toggle",true);
      ApiService.get("user/get?id="+this.id).then(response =>{
        self.user=response.data;
        self.getUserTags();
        self.getAllWithSameLabelRole();
        self.getAllWithSameCompany();
        self.$store.dispatch("loading/success");

      })
    }
  }
}
</script>

