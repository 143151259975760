<template>
<div class="py-5">
  <h3 class="mx-5 my-2">People you share interests with</h3>
  <LobbySlider :users="users"/>
</div>
</template>

<script>
import ApiService from "@/services/api.service";
import LobbySlider from "@/views/Lobby/LobbySlider";

export default {
  name: "ForYouPage",
  components: {LobbySlider},
  data(){
    return{
      users:[]
    }
  },
  created() {
    this.getUsers();
  },
  methods:{
    getUsers(){
      let self=this;
      ApiService.get("user/getAll").then(response => {
        self.users=response.data;
        for(let user of self.users){
          user.bigDisplay=Math.random() < 0.1;
        }
      })
    },
  }
}
</script>

<style scoped>

</style>