import ApiService from "@/services/api.service"

export async function followUser(userId) {
    let response = await ApiService.get("user/follow/" + userId);
    return response.data;
}

export async function unFollowUser(userId){
    let response = await ApiService.get("user/unfollow/"+userId);
    return response.data;
}
export async function getFeedOfUser(userId,page){
    let response = await ApiService.get("feed/user/"+userId+"/"+page);
    return response.data;
}
export async function getMyFeed(page){
    if(!page) page=0;
    let response = await ApiService.get("feed/my/"+page);
    return response.data;
}
export async function createFeed(feed){
    let response = await ApiService.post("feed/create",feed);
    return response.data;
}
export async function updateFeed(feed){
    let response = await ApiService.post("feed/update",feed);
    return response.data;
}
export async function likeOrDislikeFeed(like,feedId){
    let url = null;
    if(like) url = "feed/like/"+feedId;
    else url = "feed/dislike/"+feedId;
    let response = await ApiService.get(url);
    return response.data;
}
export async function addComment(feedId,text){
    let response = await ApiService.post("feed/comment/add",{id:feedId,text:text});
    return response.data;
}
export async function deleteComment(commentId,feedId){
    let response = await ApiService.post("feed/comment/delete",{commentId:commentId,feedId:feedId});
    return response.data;
}