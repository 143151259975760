<template>
  <v-list-item two-line class="offwhite mx-5 my-2" :class="{'phantom':artistIsPhantom}">
    <v-list-item-avatar>
      <v-img :src="artist.profilePicture?'data:image/png;base64,'+artist.profilePicture : require('@/assets/Placeholder-Profile-819x1024.webp')"/>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
<span class="font-weight-bold mr-5" style="font-size: 1.3rem;" v-html="artist.name"></span><v-chip x-small v-for="year in artist.years" :key="year">{{year}}</v-chip>
      </v-list-item-title>
      <v-list-item-subtitle>
    <span v-for="(profession,index) of artist.professions" :key="index">
      <span class="mx-1" v-text="profession.company?.name"></span>
      <span v-if="index < artist.professions.length-1">|</span>
    </span>

      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-menu
          offset-y
          bottom
          left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
      <v-dialog v-model="showEditArtistDialog">
        <template #activator="{attrs,on}">
          <v-list-item link v-on="on" v-bind="attrs">
            <v-list-item-title>
              Edit Artist
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <EditUserDialogContent @update="update" @toggle="(value) => this.showEditArtistDialog = value" :user-id="artistC.id"/>
      </v-dialog>
          <v-dialog v-model="showShareDialog" persistent v-if="isMyArtist || hasRoleAdmin">
            <template v-slot:activator="{attrs,on}">
                <v-list-item link v-on="on" v-bind="attrs">
                  <v-list-item-title>Share Artist</v-list-item-title>
                  <v-list-item-action>
                    <v-icon>mdi-share</v-icon>

                  </v-list-item-action>
                </v-list-item>
            </template>
            <v-card>
              <v-card-text class="pa-5">

                <ShareToUser @updateShareList="updateArtistShareList" :editable-by="artist.editableBy"/>
                <div class="d-flex justify-end">
                  <v-btn @click="showShareDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        <v-list-item link @click="sendInvite" >
          <v-list-item-content>
          <v-list-item-title>
            Send Credentials to email
          </v-list-item-title>
          <v-list-item-subtitle>
            Email is send to {{artistC.email}}
          </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon><v-icon>mdi-send</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
          <v-list-item link @click="deleteUser">
            <v-list-item-title>
              Delete User
            </v-list-item-title>
            <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>


      <v-switch v-if="hasRoleAdmin" :color="artistC.visibleOnWebsite ? 'success' : 'grey'" inset @change="toggleWebsiteVisibilityArtist" v-model="artistC.visibleOnWebsite">
        <template #label>
          <v-icon color="success" v-if="artistC.visibleOnWebsite">mdi-eye</v-icon>
          <v-icon v-else>mdi-eye-closed</v-icon>
        </template>
      </v-switch>
    </v-list-item-action>
    <v-tooltip right>
      <template #activator="{attrs,on}">
        <v-icon v-bind="attrs" v-on="on" style="position:absolute;left:0;top:0;" class="pa-2" v-if="artistIsPhantom">mdi-ghost</v-icon>

      </template>
      This User has a temporary Email and has not logged in in his account.
    </v-tooltip>

  </v-list-item>
</template>

<script>
import {shareArtist, toggleWebsiteVisibilityArtist} from "@/services/artist.service";
import Artist from "@/models/Artist";
import ShareToUser from "@/components/share/ShareToUser";
import {deleteUser, inviteUser} from "@/services/user.service";
import EditUserDialogContent from "@/components/user/EditUserDialogContent";

export default {
  name: "ArtistListItem",
  components: {EditUserDialogContent, ShareToUser},
  props:{
    artist: Artist
  },
  data(){
    return{
      showEditArtistDialog:false,
      showShareDialog :false
    }
  },
  computed:{
    artistC:{
      get(){
        return this.artist;
      },
      set(artist){
        this.$emit("updateArtist",artist);
      }
    },

    currentUser(){
      return this.$store.state.auth.user;
    },
    hasRoleAdmin(){
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
    artistIsPhantom(){
      return this.artistC.roles.includes("ROLE_PHANTOM");
    },
    isMyArtist(){
      return this.artist.createdBy === this.currentUser.id;
    },
  },
  methods:{
    async deleteUser() {
      if(confirm("Are you sure?"))
      {
        let success = await deleteUser(this.artistC.id);
        if (success) this.$emit("deleteUser", this.artistC.id);
      }
    },
    update(artist){
      this.$emit("updateArtist",artist);
    },
    async sendInvite() {
      if (confirm("Are you sure? By clicking OK the Artist gets an Welcome Email with his new Details. The Password gets reset.")){
        await inviteUser(this.artistC.id);
    }
    },
    async updateArtistShareList(ids) {
      await shareArtist(this.artist.id, ids);
      this.showShareDialog = false;
    },
    toggleWebsiteVisibilityArtist(){
      toggleWebsiteVisibilityArtist(this.artist.id,this.artist.visibleOnWebsite);
    }
  }
}
</script>

<style scoped>
.phantom{
  opacity: 0.7;
}
</style>