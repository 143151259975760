<template>
  <v-list-item style="border-bottom:1px solid grey">
    <v-list-item-avatar>
      <v-img :src="comment.autor.profilePic?'data:image/png;base64,'+comment.autor.profilePic : require('@/assets/Placeholder-Profile-819x1024.webp')">

      </v-img>
    </v-list-item-avatar>
    <v-list-item-content>
    <v-list-item-title style="font-size: 0.7rem;">
      <span v-text="comment.autor.name"></span><span  class="font-weight-bold ml-2">{{date}}</span>
    </v-list-item-title>
    <v-list-item-subtitle class="text-wrap" v-text="comment.text">

    </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn x-small v-if="comment.autor.id === currentUser.id" icon @click="deleteComment"><v-icon>mdi-delete</v-icon></v-btn>
    </v-list-item-action>
  </v-list-item>

</template>

<script>
export default {
  name: "CommentListItem",
  props:{
    comment:Object
  },
  methods:{
    deleteComment(){
      if (confirm('Are you sure? The comment will be permanently deleted.')) {
        this.$emit("deleteComment",this.comment.id)
      } else {
       return null;
      }

    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    date(){
      let moment= require("moment");
      return moment(this.comment.creationDate).fromNow();
    }
  }
}
</script>

<style scoped>

</style>