<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
      offset-overflow
      z-index="1000"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="tile" style="z-index:99" color="secondary" :class="{'left-inset-avatar':index>=1}" icon small v-bind="attrs" v-on="on">
      <v-hover  style="cursor:pointer"
               v-slot="{ hover }">
        <v-img width="25px" heigth="25px" class="rounded-circle" :style="{'z-index':index}" :class="{'front':hover}" :src="user.image?'data:image/webp;base64,'+user.image:require('../../assets/Placeholder-Profile-819x1024.webp')"></v-img>
      </v-hover>
      </v-btn>
      <v-list-item  v-else v-bind="attrs" v-on="on">
        <v-list-item-avatar rounded>
          <v-img :src="user.image?'data:image/webp;base64,'+user.image: require('../../assets/Placeholder-Profile-819x1024.webp')"></v-img>

        </v-list-item-avatar>
        <v-list-item-title><span v-html="user.fullName"></span></v-list-item-title>
      </v-list-item>
    </template>

    <v-card
        class="custom-border-radius">
      <v-btn style="position: absolute;top:0;left:0;z-index:999" @click="menu=false" icon x-small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-list>
        <v-list-item>
          <v-list-item-avatar rounded>
            <img
                :src="user.image?'data:image/webp;base64,'+user.image : require('../../assets/Placeholder-Profile-819x1024.webp')"

            :alt="'Image of '+user.fullName"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title><span v-html="user.fullName"></span></v-list-item-title>
            <!--<v-list-item-subtitle><span v-html="user.labelRole"></span> @<span v-html="user.companyName"></span></v-list-item-subtitle>
         -->
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
                icon
            >
              <v-icon>mdi-message-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions>
        <v-slide-group
        >
          <v-slide-item>
          <v-btn v-if="user.spotify" :href="'https://open.spotify.com/artist/'+user.spotify" target="_blank" icon color="primary">
            <v-icon>mdi-spotify</v-icon>
          </v-btn>
            </v-slide-item>
          <v-slide-item>
          <v-btn v-if="user.soundcloud" :href="'https://soundcloud.com/'+user.soundcloud" target="_blank" color="primary" icon>

            <v-icon>mdi-soundcloud</v-icon>
          </v-btn>
          </v-slide-item>
          <v-slide-item>
          <v-btn v-if="user.instagram" :href="'https://www.instagram.com/'+user.instagram" target="_blank" color="primary" icon>
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
          </v-slide-item>
          <v-slide-item>
          <v-btn v-if="user.youtube" :href="'https://www.youtube.com/'+user.youtube" target="_blank" color="primary" icon>
            <v-icon>mdi-youtube</v-icon>
          </v-btn>
          </v-slide-item>
          <v-slide-item>
          <v-btn v-if="user.linkedIn" :href="'https://www.linkedin.com/in/'+user.linkedIn" target="_blank" color="primary" icon>
            <v-icon>mdi-linkedin</v-icon>
          </v-btn>
          </v-slide-item>
          <v-slide-item>
          <v-btn v-if="user.twitter" :href="'https://twitter.com/'+user.twitter" target="_blank" color="primary" icon>
            <v-icon>mdi-twitter</v-icon>
          </v-btn>
          </v-slide-item>
        </v-slide-group>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="$router.push('user/'+user.id)"
            :icon="isMobile"
        >

          <span class="hidden-sm-and-down">More Details</span>
          <v-icon class="hidden-md-and-up">mdi-open-in-new</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>

</template>

<script>
export default {
  name: "UserPopover",
  data(){
    return{
      menu:false
    }
  },
  computed:{
    isMobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        return true
      }else{
        // false for not mobile device
        return false
      }
    },
  },
  props:{
    tile:Boolean,
    index:Number,
    user:Object
  }
}
</script>

<style>
.left-inset-avatar{
  margin-left:-10px;
}
.front{
  z-index:999!important;
}
.black-border{
  border:1px solid var(--v-primary-base);
}
</style>