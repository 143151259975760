<template>
  <v-list-item class="offwhite mx-5 my-2">
    <v-list-item-avatar height="75px" width="75px" class="custom-border-radius">
      <v-img  :src="artist.profilePicture?'data:image/png;base64,'+artist.profilePicture: require('../../assets/Placeholder-Profile-819x1024.webp')"/>

    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="font-weight-bold" style="font-size: 1.3rem;" v-html="artist.name">

      </v-list-item-title>
      <v-list-item-subtitle>
    <span v-for="(profession,index) of artist.professions" :key="index">
      <span class="mr-1" v-text="profession.company?.name"></span>
      <span v-if="index < artist.professions.length-1">|</span>
    </span>

      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Artist from "@/models/Artist";

export default {
  name: "ArtistListItemPublic",
  props:{
    artist: Artist
  },
}
</script>

<style scoped>

</style>