<template>
  <v-sheet height="50vh" class="d-flex justify-center align-center" v-if="feed.length<=0">
    <h2>Your Feed is empty</h2>
  </v-sheet>
  <div v-else>
    <FeedItem v-for="feedItem of feed" :key="feedItem.id" @updateItem="updateFeedItem" :content="feedItem"/>
    <div ref="scrollTriggerElement"></div>
    <v-progress-circular size="100px" v-if="loading"></v-progress-circular>
    <div v-if="this.$store.state.feed.endOfList" class="my-5 d-flex justify-center flex-wrap">
      <v-divider  class="mx-5" inset></v-divider>
      <span style="width:100%" class="text-center text--disabled mx-auto">End of feed</span>
    </div>
  </div>
</template>

<script>
import FeedItem from "@/components/feed/FeedItem";

export default {
  name: "MyFeed",
  components: {FeedItem},
  data(){
    return{
      loading:false
    }
  },
  created() {
    this.getFeed();
  },
  methods:{
    loadMore(){
      this.$store.dispatch("feed/loadCurrentPage");
    },
    updateFeedItem(item){
      this.$set(this.feed,this.feed.findIndex(x => x.id ===item.id),item);
      //this.feed[this.feed.findIndex(x => x.id ===item.id)] = item;
    },
    getFeed() {
      this.$store.dispatch("feed/loadFeedItems");
    },
    isScrolledToBottom() {
      // Calculate the scroll position
      const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
      const bodyHeight = document.body.scrollHeight;

      // Check if the scroll position is at the bottom
      return scrollTop + windowHeight >= bodyHeight;
    },
    onScrollToBottom() {
      console.log("Scrolled to the bottom!");
      if(this.loading || this.$store.state.feed.endOfList) return null;
      this.loading=true;
      this.$store.dispatch("feed/loadCurrentPage");
      // Add your code here to execute when scrolled to the bottom
    },
    handleScroll() {
      // Check if scrolled to the bottom and execute the function
      if (this.isScrolledToBottom()) {
        this.onScrollToBottom();
      }
    },
  },
  mounted() {
    // Add a scroll event listener when the component is mounted
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // Remove the scroll event listener when the component is destroyed
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch:{
  },
  computed:{
    feed(){
      return this.$store.state.feed.items;
    }
  }
}
</script>

<style scoped>

</style>