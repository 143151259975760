<template>
<v-img :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file :require('@/assets/Placeholder-Profile-819x1024.webp')">

</v-img>
</template>

<script>
export default {
  name: "UserAvatar",
  props:{
    user:Object
  }
}
</script>

<style scoped>

</style>