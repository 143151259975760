import { render, staticRenderFns } from "./ImagePickerWithCropping.vue?vue&type=template&id=10fe5735&scoped=true"
import script from "./ImagePickerWithCropping.vue?vue&type=script&lang=js"
export * from "./ImagePickerWithCropping.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10fe5735",
  null
  
)

export default component.exports