<template>
<div>
  <FeedItem v-for="feedItem of feed" :key="feedItem.id" @updateItem="updateFeedItem" :content="feedItem"/>
</div>
</template>

<script>
import FeedItem from "@/components/feed/FeedItem";
import {getFeedOfUser} from "@/services/lobby.service";
export default {
  name: "FeedOfUser",
  components: {FeedItem},
  props:{
    userId:String
  },
  data(){
    return{
      page:0,
      feed:[],
    }
  },
  created() {
    this.getFeed();
  },
  methods:{
    updateFeedItem(item){
      this.feed[this.feed.findIndex(x => x.id ===item.id)] = item;
    },
    async getFeed() {
      this.feed = await getFeedOfUser(this.userId, this.page);
      this.page++;
    }
  }
}
</script>

<style scoped>

</style>