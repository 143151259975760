<template>
<v-form class="pa-5">
  <label class="custom-label">
    Display<span>name</span>
    <v-text-field class="input-border" solo v-model="user.name"/>
  </label>
  <label class="custom-label">
   Firstname
    <v-text-field class="input-border" solo v-model="user.firstName"/>
  </label>
  <label class="custom-label">
   Lastname
    <v-text-field class="input-border" solo v-model="user.lastName"/>
  </label>
  <label class="custom-label">
    Email
    <v-text-field class="input-border" solo v-model="user.email"/>
  </label>
  <label class="custom-label">
    Bio
    <v-textarea class="input-border" solo>

    </v-textarea>
  </label>
  <label class="custom-label">
    Genre
  <div class="d-flex justify-start flex-wrap">
    <GenrePicker @update="updateGenres"/>
  </div>
  </label>
  <label class="custom-label">
    Profession
    <div class="d-flex justify-space-between flex-nowrap">
      <v-text-field placeholder="Role" v-model="user.role" solo class="input-border"/>
      <v-autocomplete @keypress.enter="addCompany(companyToSearch)" item-text="name" item-value="id" :items="companies"
                      :search-input.sync="companyToSearch" v-model="user.company" class="input-border"
                      prefix="@" placeholder="Company" color="transparent" solo>
        <template v-slot:no-data>
          <v-list-item link @click="addCompany()">
            <span>Create Company: <span v-html="companyToSearch"></span></span>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </label>
  <label class="custom-label">
    Socials
    <v-text-field class="input-border nomargin" prepend-icon="mdi-earth" solo dense v-model="user.website" placeholder="..." prefix="https://soundcloud.com/"></v-text-field>
    <v-text-field class="input-border nomargin" prepend-icon="mdi-soundcloud" solo dense v-model="user.soundcloud" placeholder="..." prefix="https://soundcloud.com/"></v-text-field>
    <v-text-field class="input-border nomargin" prepend-icon="mdi-youtube" solo dense v-model="user.youtube" placeholder="..." prefix="https://www.youtube.com/"></v-text-field>
    <v-text-field class="input-border nomargin" prepend-icon="mdi-spotify" solo dense v-model="user.spotify" placeholder="..." prefix="https://open.spotify.com/artist/"></v-text-field>
    <v-text-field class="input-border nomargin" prepend-icon="mdi-linkedin" solo dense v-model="user.linkedIn" placeholder="..." prefix="https://www.linkedin.com/in/"></v-text-field>
    <v-text-field class="input-border nomargin" prepend-icon="mdi-twitter" solo dense v-model="user.twitter" placeholder="..." prefix="https://twitter.com/"></v-text-field>
    <v-text-field class="input-border nomargin" prepend-icon="mdi-instagram" solo dense v-model="user.instagram" placeholder="..." prefix="https://www.instagram.com/"></v-text-field>
  </label>
  <div style="max-width: 70%" class="d-flex mx-auto justify-space-between">
    <v-btn @click="cancel" :disabled="loading" text>Cancel</v-btn><v-btn :loading="loading" @click="createAUser" outlined>Save</v-btn>
  </div>
</v-form>
</template>

<script>
import {createUser} from "@/services/user.service";
import User from "@/models/userToCreate";
import {addCompany, getAllCompanies} from "@/services/profession.servie";
import GenrePicker from "@/components/artist/GenrePicker";
import Notification from "@/models/notification";

export default {
  name: "CreateUser",
  components: {GenrePicker},
  props:{
    role: String,
    presetUsername:String
  },
  data(){
    return{
      companies:[],
      companyToSearch:"",
      user:new User(),
      loading:false
    }

  },
  async created() {
    if(this.presetUsername) this.user.name=this.presetUsername;
    this.companies = await getAllCompanies();
  },
  methods:{
    cancel(){
      this.$emit("cancel");
    },
    updateGenres(genres){
      this.user.genre=genres;
    },
    async addCompany() {
      let newCompany = await addCompany(this.companyToSearch);
      this.companies.push(newCompany);
    },
    async createAUser() {
      this.loading=true;
      let success = await createUser(this.user, this.role);
      if(success) {
        this.$store.dispatch("notificationsStore/add",new Notification("success","User erfolgreich erstellt.",false));
        this.$emit("save");
      }
      this.loading=false;
    }
}
}
</script>

