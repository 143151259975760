<template>
  <v-footer app>
<v-app-bar flat color="transparent" max-height="60px" style="position: relative">
  <v-progress-linear v-if="$route.name==='Welcome'" style="position:absolute;left:0;top:0;width:100%;z-index:99999" color="accent" v-model="progress"></v-progress-linear>
  <v-btn icon :disabled="$route.name==='Feed'" @click="$router.push('/feed')"><v-icon large>mdi-home-variant</v-icon></v-btn>
  <v-spacer></v-spacer>
  <v-btn icon :disabled="$route.name==='Lobby'" @click="$router.push('/lobby')"><v-icon large>mdi-compass</v-icon></v-btn>
  <v-spacer></v-spacer>

  <v-dialog v-model="createFeedDialog" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on"><v-icon large>mdi-plus</v-icon></v-btn>
    </template>
    <CreateFeedItem @toggleDialog="toggleCreateFeedDialog"/>
  </v-dialog>
  <v-spacer></v-spacer>
  <v-btn icon :disabled="$route.name==='Favourites'" @click="$router.push('/favourites')"><v-icon>mdi-bookmark-multiple</v-icon></v-btn>
  <v-spacer></v-spacer>
  <v-btn icon :disabled="$route.name==='Events'" @click="$router.push('/events')" large><v-icon>mdi-timetable</v-icon></v-btn>



</v-app-bar>
  </v-footer>
</template>

<script>
import CreateFeedItem from "@/components/feed/CreateFeedItem";
export default {
  name: "MobilebarBottom",
  components: {CreateFeedItem},
  methods:{
    handleLogout:function(){
      this.$store.dispatch('auth/logout')
      this.$router.push("/")
    },
    toggleCreateFeedDialog(value){
      this.createFeedDialog=value;
    }
  },
  data(){
    return{
      createFeedDialog:false,
    }
  },
  computed:{
    page:{
      get() {
        return this.$store.state.app.welcome.page
      },
      set(value){
        this.$store.state.app.welcome.page=value;
      }
    },
    pages(){
      return this.$store.state.app.welcome.pages
    },
    progress(){
      return ((this.page)/(this.pages))*100;
    },
    currentUser(){
      return this.$store.state.auth.user;
    },

      chatPartners(){
        return this.$store.state.chat.currentChatPartners
      },
      unreadMessages(){
        const sum = this.chatPartners.reduce((accumulator, object) => {
          return accumulator + object.newMessages;
        }, 0);
        return sum;
      }
  }
}
</script>

<style scoped>

</style>