

const initialState = {
    stompClient:null,
    connectionStatus:false,
    contacts:[],
    activeContact:null,
    messages:[],
    currentChatPartners:[]
}

export const chat = {
    namespaced: true,
    state: initialState,
    actions: {
        contacts({commit},contacts){
          commit("contacts",contacts)
        },
        changeConnectionStatus({commit},status){
            commit("changeConnectionStatus",status)
        },
        changeActiveContact({commit},contact){
            commit("changeActiveContact",contact)
        },

        connect({ commit }, client) {
            commit("connect",client)
        },
        messages({commit},messages){
          commit("messages",messages)
        },
        chatPartners({commit},users){
            commit("chatPartners",users)
        }
    },

    mutations: {
        changeConnectionStatus(state, status) {
            state.connectionStatus=status;
        },
        chatPartners(state,users){
            for(let user of users){
               if(!user.newMessages) user.newMessages=0
                if(!user.lastMessage) user.lastMessage=null
            }
            state.currentChatPartners=users;
        },
        connect(state, client) {
            state.stompClient = client;
        },
        contacts(state,contacts){
            state.contacts=contacts;
        },
        changeActiveContact(state,contact){
            state.activeContact=contact;
        },
        messages(state,messages){
            state.messages=messages;
        }
    }
};