<template>
<div style="max-width: 600px" class="mx-auto">
  <v-dialog max-width="600px" width="600px" v-model="createFeedDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="hidden-sm-and-down" icon elevation="5" fab style="position:fixed; right:25px;bottom:25px;" v-bind="attrs" v-on="on"><v-icon large>mdi-plus</v-icon></v-btn>
    </template>
    <CreateFeedItem @toggleDialog="toggleCreateFeedDialog"/>
  </v-dialog>
  <MyFeed/>
</div>
</template>

<script>
import MyFeed from "@/components/feed/MyFeed";
import CreateFeedItem from "@/components/feed/CreateFeedItem";
export default {
  name: "MyFeedView",
  components: {CreateFeedItem, MyFeed},
  data(){
    return{
      createFeedDialog:false
    }
  },
  methods:{
    toggleCreateFeedDialog(value){
      this.createFeedDialog=value;
    }
  }
}
</script>

<style scoped>

</style>