<template>
<v-sheet elevation="1" rounded color="secondary" min-width="300px" height="100px" min-height="100px" class="pa-2 hvr-grow">
  <v-layout fill-height justify-space-between>
    <v-flex xs3 class="d-flex align-center">
      <v-img
          height="100%"
          class="rounded"
          style="position: relative;"
          :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file :require('../../assets/Placeholder-Profile-819x1024.webp')"
      ></v-img>
    </v-flex>
    <v-flex class="body-2 d-flex flex-column align-center justify-center flex-wrap" xs8>

      <span style="width: 100%;" class="text-uppercase font-weight-bold"><span v-html="user.firstName+' '+user.lastName"> </span></span>
        <small style="width: 100%;margin-top:-7px;" class="caption" color="primary lighten-2"><span v-for="(tag,index) of tags" :key="tag.id">{{tag.name}} <span v-if="index<tags.length-1">/ </span></span></small>
        <span style="width:100%" class="text-left"><span v-html="user.labelRole"></span>
        <span ><span>@</span><span v-html="user.companyName"></span></span></span>

    </v-flex>
  </v-layout>
</v-sheet>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: "UserSheet",
  props:{
    user:Object
  },
  data(){
    return{
    tags:[]
    }
  },
  created() {
    this.getUserTags()
  },
  methods:{
    getUserTags(){
      let self=this;
      console.log(self.user.tags)
      ApiService.post("tags/get/custom",{ids:self.user.tags}).then(response => {
        self.tags=response.data;
        console.log(response.data)
        self.$store.dispatch("loading/success");
      })
    },
  }
}
</script>

<style scoped>

</style>