<template>
<AdminUserList/>
</template>

<script>
import AdminUserList from "@/components/management/user/AdminUserList";
export default {
  name: "FullUserList",
  components: {AdminUserList}
}
</script>

<style scoped>

</style>