<template>
<v-container>
  <v-row>
    <v-col class="d-flex justify-center align-center">
      <div style="background-color:var(--v-offwhite-base);width:150px;height:150px" class="custom-border-radius align-content-center d-flex flex-wrap justify-center align-center" >
        <div style="width:100%;" class="text-center">All Artists</div>
        <span style="font-size: 2.5rem;" v-text="managementResponse.countArtists"/>
      </div>
    </v-col>
    <v-col class="d-flex justify-center align-center">
      <div style="background-color:var(--v-offwhite-base);width:150px;height:150px" class="custom-border-radius align-content-center d-flex flex-wrap justify-center align-center" >
        <div style="width:100%;" class="text-center">All Events</div>
        <span style="font-size: 2.5rem;" v-text="managementResponse.countEvents"/>
      </div>
    </v-col>
  </v-row>
  <v-tabs class="my-5" v-model="tab">
    <v-tab>Artists</v-tab>
    <v-tab>Events</v-tab>
    <v-tab>Venues</v-tab>
    <v-tab v-if="hasRoleAdmin">Users</v-tab>
  </v-tabs>
  <v-tabs-items v-model="tab">
    <v-tab-item>
      <AdminArtistList/>
    </v-tab-item>
    <v-tab-item>
      <AdminEventList/>
    </v-tab-item>
    <v-tab-item>
     <VenueAdminList/>
    </v-tab-item>
    <v-tab-item v-if="hasRoleAdmin">
      <FullUserList/>
    </v-tab-item>
  </v-tabs-items>
  <v-row>
    <v-col cols="12">

    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">

    </v-col>
  </v-row>

</v-container>
</template>

<script>
import {getFullManagementResponse} from "@/services/management.service";
import AdminArtistList from "@/views/artist/AdminArtistList";
import ManagementResponse from "@/models/managementResponse";
import AdminEventList from "@/components/management/events/AdminEventList";
import VenueAdminList from "@/components/management/venue/VenueAdminList";
import FullUserList from "@/views/conferenceManagement/FullUserList";

export default {
  name: "ManagementDashboard",
  components: {FullUserList, VenueAdminList, AdminEventList, AdminArtistList},
  data(){
    return{
      managementResponse:new ManagementResponse(),
      tab:null
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    hasRoleAdmin(){
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
  },
  created() {
    this.getManagementResponse();
  },
  methods:{
    async getManagementResponse() {
      this.managementResponse = await getFullManagementResponse();
    }
  }
}
</script>

<style scoped>

</style>