<template>
<v-dialog v-model="showC">
  <v-sheet height="80vh">
    <v-toolbar flat>
      <v-toolbar-title><h3>Payment</h3></v-toolbar-title>
      <v-spacer/>
      <v-toolbar-items>
        <v-btn @click="showC=false" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <iframe height="100%" width="100%" :src="paymentLink"></iframe>
  </v-sheet>
</v-dialog>
</template>

<script>
export default {
  name: "PayOrder",
  props:{
    paymentLink:String,
    show:Boolean
  },
  computed:{
    showC:{
      get(){
        return this.show;
      },
      set(value){
        this.$emit("toggle",value);
      }
    }
  }

}
</script>

<style scoped>

</style>