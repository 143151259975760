<template>
<v-container style="height:100%;">
  <v-row style="height: 100%">
    <v-col xs="12" md="6">
      <v-list class="px-2">
        <h3 class="mx-auto pa-5 text-center">Artists of {{event.name}}</h3>
        <ArtistWithNameAndImage @removeArtist="removeArtist" :event-id="eventId" eventMember v-for="artist of eventArtists" :key="artist.id" :artist="artist"></ArtistWithNameAndImage>
      </v-list>
    </v-col>
    <v-col style="border-left:2px solid grey" xs="12" md="6">
      <v-list class="px-2">
        <v-text-field v-model="searchUser" placeholder="Search Users..."></v-text-field>

        <ArtistWithNameAndImage @addArtist="addArtist" :event-id="eventId" v-for="artist of filteredArtists" :key="artist.id" :artist="artist"/>

        <v-dialog
            v-model="createArtistDialog"
            fullscreen
            v-if="filteredArtists.length<=0"
        >
          <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs"
                     v-on="on" link >
          <v-list-item-avatar>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold" style="font-size: 1.3rem;">
              That artist does not exist.
            </v-list-item-title>
            <v-list-item-subtitle>
              Click here to create
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
          </template>
          <v-card>
            <CreateUser :presetUsername="searchUser" @cancel="() => createArtistDialog =false" @save="update"/>
          </v-card>
        </v-dialog>
      </v-list>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import ArtistWithNameAndImage from "@/components/event/ArtistWithNameAndImage";
import { getAllArtists, getAllArtistsByEvent} from "@/services/artist.service";
import CreateUser from "@/components/artist/CreateUser";
export default {
  name: "EventArtists",
  components: {CreateUser, ArtistWithNameAndImage},
  props: {
    eventId: String
  },
  computed: {
    filteredArtists(){
      return this.allArtistsWithoutEventArtists.filter(artist => artist.name.toLowerCase().includes(this.searchUser.toLowerCase()));
    },
    allArtistsWithoutEventArtists() {
      const ids = this.eventArtists.map(item => item.id);
      let result = this.allArtists.filter(item => !ids.includes(item.id));
    return result;
    }
  },
  data() {
    return {
      createArtistDialog:false,
      searchUser: "",
      event: {
        name: "Test"
      },
      eventArtists: [],
      allArtists: []
    }
  },
  created() {
    this.getAllArtists();
    this.getArtistsOfEvent();
    this.createArtistDialog=false;
  },
  methods: {
    update(){
      this.getAllArtists();
      this.getArtistsOfEvent();
    },
    addArtist(artist){
      this.eventArtists.push(artist);
      this.allArtists.splice(this.allArtists.indexOf(x=> x.id ==artist.id),1);
    },
    removeArtist(artist){
      this.eventArtists.splice(this.eventArtists.indexOf(x=> x.id ==artist.id),1);
    },
    async getAllArtists() {
      this.allArtists = await getAllArtists();
    },
    async getArtistsOfEvent() {
      this.eventArtists = await getAllArtistsByEvent(this.eventId);
    },
  }
}
</script>

<style scoped>

</style>