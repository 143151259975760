<template>
<v-row style="height:100vh">
  <v-col class="d-flex  align-center" cols="6">
    <div class="mx-auto px-5" style="max-width: 400px">
      <v-img width="150" :src="require('@/assets/logoBig.webp')"></v-img>
    <h1>Log In to your account</h1>
      <h4 class="mb-5 font-weight-regular">Welcome back! Select method to log in.</h4>
      <v-btn style="height: 50px;
    width: 100%;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px -10px;
    border-color: rgb(238, 238, 238);
    border-radius: 50px;" outlined color="grey" disabled large class="mx-auto my-1 align-self-center" :loading="loading" width="100%" :href="'https://bdme.auth.eu-central-1.amazoncognito.com/login?client_id=6fb837a28n4589dkr8r5c9n6vb&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri='+frontendhost+'/login'">
        <span>Google/ Facebook</span>
      </v-btn>
    <div class="mt-5 mb-2 d-flex align-center" style="gap: 20px;"><v-divider/><span class="text--disabled">Or continue with email</span><v-divider/></div>

      <v-form ref="form" class="d-flex justify-center flex-wrap" style="gap:15px" v-model="valid">
        <v-text-field
            solo
            class="custom-login-input"
            rounded
            autocomplete="username"
            autofocus
            style="width:200px"
            hide-details
            label="Email Address"
            type="email"
            v-model="user.email"
            required
        />
        <v-text-field label="Password"
                      v-model="user.password"
                      style="width:100%"
                      autocomplete="current-password"
                      solo
                      rounded
                      hide-details
                      class="custom-login-input"
                      @keypress.enter="handleLogin"
                      :rules="passwordRules"
                      type="password"
                      required
        />

        <v-btn block color="accent" class="mx-auto align-self-center" :loading="loading" width="100px" @click="handleLogin">
          <span>Login</span>
        </v-btn>
      </v-form>
      <div class="d-flex justify-end" style="width:100%">
        <v-btn plain light small href="/u/reset/password/request" class=" float-right">Forgot Password?</v-btn>
      </div><v-alert type="error" class="ma-3" v-if="message" dismissible color="red">{{message}}</v-alert>
      <div class="mx-auto mt-5 d-flex justify-center align-center" style="width: 100%; font-size: 0.8rem;">Don't have an account? <v-btn @click="$router.push('/register')" plain text light class="text-lowercase">Create an account</v-btn></div>

    </div>
</v-col>
  <v-col class="primary" cols="6">
  </v-col>
</v-row>
</template>

<script>
import {getCognitoToken, loginWithCognito} from "@/services/cognito.service";
import Notification from "@/models/notification";

export default {
  name: 'LoginView',
  data() {
    return {

      valid: true,
      user: {
        email:'',
        password:''
      },
      message: '',
      loading:false,
      username:'',
      usernameRules:[
        v => !!v || this.$t('login.nameRequired'),
        v => (v && v.length <= 20) || this.$t('login.nameLength'),
      ],
      password:'',
      confirmPassword:"",
      passwordRules:
          [
            v => !!v || this.$t('login.passwordRequired'),
          ]

    };
  },
  computed: {
    frontendhost(){
      return process.env.VUE_APP_FRONTHOST;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    //console.log("Der COde ist: "+this.$route.query.code);
    if (this.loggedIn) {
      this.$router.push('/profile');
    } else if (this.$route.query.code) {
      console.log("Client is logging in with Cognito");
      const promise = getCognitoToken(this.$route.query.code)
          promise.then(tokenObject => {
        this.$store.state.loading.loginCognitoProcess.tokenObject = tokenObject;
        console.log("Token: "+tokenObject);
        if (tokenObject.extraEmail) {
          loginWithCognito(tokenObject);
        } else {
          this.$store.dispatch("notificationsStore/add", new Notification("warning", "Please provide your email", false))
        }
      });

    }
  },
  methods: {

    handleLogin() {
      this.loading = true;
      /**
       this.$validator.validateAll().then(isValid => {
                    if (!isValid) {
                        this.loading = false;
                        return;
                    }
       **/
      if (this.user.email && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/profile');
            },
            () => {
              this.loading = false;
              this.message = "You entered the wrong email or password"
            }
        );
      }

    }
  }
};
</script>
<style>

</style>