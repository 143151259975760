<template>
  <v-card style="height:90vh" flat class="px-5 pt-5">
    <div style="height: 80%">

      <v-img class="mx-auto" v-if="!$vuetify.theme.dark" height="100px" width="100px" :src="require('../assets/logo.png')"></v-img>
      <v-img class="mx-auto" v-else height="100px" width="100px" :src="require('../assets/Logo_w_transparent.png')"></v-img>

      <v-card-title class="d-flex justify-center"><span style="width:100%" class="text-center">Welcome to the</span> <br/>
        <b class="text-center font-weight-bold">Berlin Dance Music Event <br/> 2024</b></v-card-title>
    <v-form ref="form" class="mx-5" v-model="valid">

      <v-text-field
          autofocus
          solo
          rounded
          label="Enter First Name"
          v-model="user.firstName"
          required
      />
      <v-text-field
          autofocus
          solo
          rounded
          label="Enter Last Name"
          v-model="user.lastName"
          required
      />
      <v-text-field
          autofocus
          solo
          rounded
          label="Enter Artist- /Nickname"
          v-model="user.name"
          :counter="20"
          :rules="usernameRules"
          required
      />
      <v-text-field
          solo
          type="email"
          rounded
          label="Enter your email address"
          v-model="user.email"
          :rules="emailRules"
          required
      />

      <v-text-field
          solo
          rounded
          autocomplete="current-password"
          :value="userPassword"
          label="Enter password"
          :hint="$t('registration.passwordCheck')"
          :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (value = !value)"
          :type="value ? 'password' : 'text'"
          :rules="[rules.password,rules.required]"
          @input="_=>userPassword=_"
      ></v-text-field>
      <v-text-field
          solo
          autocomplete="current-password"
          :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (value = !value)"
          :type="value ? 'password' : 'text'"
          rounded
          label="Confirm password"
          v-model="confirmPassword"
          :rules="[[v => !!v || 'Password is required'], (this.userPassword === this.confirmPassword) || 'Passwords must match']"
          required
      />
      <v-row>
        <v-col class="shrink d-flex align-center">
          <v-checkbox class="ma-0 pa-0" :rules="[v => !!v]" hide-details>

          </v-checkbox>
        </v-col>
        <v-col class="d-flex align-center">
          <span>
            <span>I accept the</span> <a class="mx-1" href="https://berlin-dance-music-event.com/agb/"><span> terms and conditions </span></a> and <a class="mx-1" href="https://berlin-dance-music-event.com/privacy-policy/">data protection
          </a>
            </span>
        </v-col>
      </v-row>

      <div class="py-5 d-flex justify-center">
      <v-btn color="success" :disabled="!valid" class="mr-2" @click="handleRegister">{{ $t('registration.btnOk') }}</v-btn>
      <v-btn plain @click="$router.push('/login')">Login</v-btn>
      </div>
    </v-form>
    <v-alert
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
    >{{message}}</v-alert>
    </div>
  </v-card>
</template>

<script>
import User from '../models/userRegister';
export default {
  name: 'RegisterView',
  data() {
    return {
      usernameRules:[
        v => !!v || this.$t('registration.nameRequired'),
        v => (v && v.length <= 20) || this.$t('registration.nameLength'),
      ],
      emailRules: [
        v => !!v || this.$t('registration.emailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('registration.emailValid'),
      ],

      user: new User('', '', '','','',''),
      url:null,
      confirmPassword:"",
      submitted: false,
      successful: false,
      message: '',
      userPassword: "",
      valid: true,
      value: true,
      rules: {
        required: value => !!value || this.$t('registration.passwordRequired'),
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          return (
              pattern.test(value) ||
              this.$t('registration.passwordCheck')
          );
        }
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {
    Preview_image(e){
      let files = e.target.files || e.dataTransfer.files;
      if(!files.length) return;
      this.user.image=files[0]
      this.url=URL.createObjectURL(this.user.image)
    },
    handleCancel(){
      this.$refs.form.reset()
    },

    handleRegister() {
      this.message = '';
      this.submitted = true;
      if (this.valid) {
        console.log(this.user);
        this.user.password=this.userPassword;
        this.$store.dispatch('auth/register', this.user).then(
            () => {
              this.message = "Erfolgreich Registriert";
              //ImageUpload(this.user.image,data);
              this.successful = true;
              this.$router.push("/welcome");

            },
            error => {
              this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              this.successful = false;
            }
        );
      }
      //this.$router.push('/successful-registration?email='+this.user.email);
    }
  }


};
</script>

<style scoped>

</style>