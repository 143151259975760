<template>
<div>

</div>
</template>

<script>
    export default {
        name: "LogoutView",
        created(){
            this.$store.dispatch('auth/logout')
            this.$router.push("/")
        }
    }
</script>

<style scoped>

</style>