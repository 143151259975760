export default class Notification {

    constructor(type,text,animation,subtext,action,buttonText) {
        this.type=type;
        this.text=text;
        this.animation=animation;
        this.subtext=subtext;
        this.action=action;
        this.buttonText=buttonText;
    }
}