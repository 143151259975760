import ApiService from "@/services/api.service";

export async function getAllCompanies() {
    let response = await ApiService.get("company/find/all")
    return response.data;
}
export async function addCompany(companyName){
    let response = await ApiService.post("company/add",{id:null,name:companyName})
    return response.data;
}

export async function updateProfession(profession){
    let response = await ApiService.post("user/profession/update/new", profession);
    return response.data;
}
export async function removeProfession(id){
    let response = await ApiService.get("user/profession/remove/new/"+id);
    return response.data;
}