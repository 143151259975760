<template>
<v-dialog persistent v-model="loading" fullscreen>
  <v-card class="d-flex justify-center flex-column align-center flex-wrap">
    <div class="text-center" style="max-width: 50%" v-if="this.$store.state.loading.loginCognitoProcess.isNeedingExtraEmail">
    <h4>The account you want to link has no email address. <br/> to proceed with creating your BDME User please enter your email</h4>
    <v-text-field :loading="waitingForServer"  solo v-model="email" append-icon="mdi-send" @keydown.enter="setEmail" @click:append="setEmail"  placeholder="Enter your Email Address here"/>
    </div>
    <v-progress-circular class="mx-auto" v-else indeterminate />
    <div style="width: 100%" class="d-flex justify-center"><v-btn text>Cancel</v-btn></div>

  </v-card>
</v-dialog>
</template>

<script>

import {loginWithCognito} from "@/services/cognito.service";

export default {
  name: "CognitoLoginPopup",
  data(){
    return{
      email:null,
      waitingForServer:false
    }
  },
  beforeDestroy() {
    this.waitingForServer=false
  },
  methods:{
    setEmail(){
      this.waitingForServer=true;
      this.$store.state.loading.loginCognitoProcess.tokenObject.extraEmail=this.email;
      console.log(this.$store.state.loading.loginCognitoProcess.tokenObject);
      loginWithCognito(this.$store.state.loading.loginCognitoProcess.tokenObject);

    }
  },
  computed:{
    loading(){
      return this.$store.state.loading.loginCognitoProcess.isLoading
    }
  }
}
</script>

<style scoped>

</style>