<template>
  <v-list-item three-line class="offwhite mx-5 my-2" :class="{'phantom':isPhantom}">
    <v-list-item-avatar>
      <v-img :src="user.profilePicture?'data:image/png;base64,'+user.profilePicture : require('@/assets/Placeholder-Profile-819x1024.webp')"/>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <span class="font-weight-bold mr-5" style="font-size: 1.3rem;" ><span v-html="user.name"></span></span><br/>
        <span v-text="user.fullName"></span><br/>
        <div class="text-wrap d-flex justify-start flex-wrap">
        <v-chip class="text-wrap my-1" x-small v-for="role of user.roles" :key="role">{{role}}</v-chip>
        </div>
      </v-list-item-title>
      <v-list-item-subtitle>
    <span v-for="(profession,index) of user.professions" :key="index">
      <span class="mx-1" v-text="profession.company?.name"></span>
      <span v-if="index < user.professions.length-1">|</span>
    </span>

      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-menu
          offset-y
          bottom
          left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-dialog v-model="showEditUserDialog">
            <template #activator="{attrs,on}">
              <v-list-item link v-on="on" v-bind="attrs">
                <v-list-item-title>
                  Edit User
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <EditUserDialogContent @toggle="(value) => this.showEditUserDialog = value" :user-id="userC.id"/>
          </v-dialog>
          <v-dialog v-model="showEditRoles">
            <template #activator="{attrs,on}">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-title>Edit Roles</v-list-item-title>
                <v-list-item-icon><v-icon>mdi-account-settings</v-icon></v-list-item-icon>
              </v-list-item>
            </template>
            <v-sheet class="pa-5">
            <EditUserRoles :user-id="user.id" @updateUser="updateUser" :selected-roles="user.roles"/>
            </v-sheet>
          </v-dialog>
          <v-dialog v-model="sendMailDialog" width="600px">
            <template #activator="{attrs,on}">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title>Send Welcome</v-list-item-title>
                <v-list-item-icon><v-icon>mdi-send</v-icon></v-list-item-icon>
              </v-list-item>
            </template>
            <SendAccountEmail :user-id="user.id" @close="() => this.sendMailDialog = false"/>
          </v-dialog>
          <v-list-item link @click="deleteUser">
            <v-list-item-title>
              Delete User
            </v-list-item-title>
            <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
    <v-tooltip right>
      <template #activator="{attrs,on}">
        <v-icon v-bind="attrs" v-on="on" style="position:absolute;left:0;top:0;" class="pa-2" v-if="isPhantom">mdi-ghost</v-icon>

      </template>
      This User has a temporary Email and has not logged in in his account.
    </v-tooltip>

  </v-list-item>
</template>

<script>
import EditUserRoles from "@/components/user/EditUserRoles";
import SendAccountEmail from "@/components/management/user/SendAccountEmail";
import EditUserDialogContent from "@/components/user/EditUserDialogContent";
import {deleteUser} from "@/services/user.service";

export default {
  name: "AdminUserListItem",
  components: {EditUserDialogContent, SendAccountEmail, EditUserRoles},
  props:{
    user:Object
  },
  data(){
    return{
      sendMailDialog:false,
      showEditUserDialog:false,
      showEditRoles:false
    }
  },
  methods:{
    async deleteUser() {
      if(confirm("Are you sure?"))
      {
        let success = await deleteUser(this.userC.id);
        if (success) this.$emit("deleteUser", this.userC.id);
      }
    },
    updateUser(user){
      this.$emit("updateUser",user);
      this.showEditRoles=false;
    }
  },
  computed:{
    userC:{
      get(){
        return this.user;
      },
      set(user){
        this.$emit("updateUser",user);
      }
    },

    currentUser(){
      return this.$store.state.auth.user;
    },
    hasRoleAdmin(){
      return this.currentUser.roles.includes("ROLE_ADMIN");
    },
    isPhantom(){
      return this.userC.roles.includes("ROLE_PHANTOM");
    },
  },
}
</script>

