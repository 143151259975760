<template>
<div>
  <!-- ANDROID PWA INSTALL PROMPT  -->
  <v-banner
      color="info"
      v-if="deferredPrompt"
      style="position:absolute;top:0;left:0;width:100vw;z-index:9999"
      dark
      class="text-left"
  >

    Install our BDME APP for better experience!

    <template v-slot:actions>
      <v-btn text @click="dismiss">Dismiss</v-btn>
      <v-btn text @click="install">Install</v-btn>
    </template>
  </v-banner>

  <!-- IOS PWA INSTALL PROMPT  -->
  <PWAPrompt :permanentlyHideOnDismiss="false"/>

</div>
</template>

<script>

import PWAPrompt from 'vue2-ios-pwa-prompt'
import {getCookie, setCookie} from "@/services/cookie.service";

export default {
  name: "InstallPwaPrompt",
  components:{
    PWAPrompt
  },
  data(){
    return{
      deferredPrompt:null
    }
  },
  created() {
    if(!getCookie("disable-pwa-prompt")) this.installPWA();
  },
  methods:{
    async dismiss() {
      this.deferredPrompt = null;
      setCookie("disable-pwa-prompt",true)
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    installPWA(){
      console.log("Cookie",getCookie("disable-pwa-prompt"));
      console.log("Asking User for PWA install?")
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        console.log("Prompt "+e);
      });
      window.addEventListener("appinstalled", () => {
        console.log("app installed");
        this.deferredPrompt = null;
        setCookie("disable-pwa-prompt",true)
      });

    },
  }
}
</script>

<style scoped>

</style>