<template>
  <v-container>
    <h1 class="py-5">Buy Tickets</h1>
  <v-row justify="center">
    <v-col
        v-for="product in tickets"
        :key="product.uid"
        cols="12"
    >
      <SingleTicketToBuy :ticket="product" />
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import SingleTicketToBuy from "@/components/ticket/SingleTicketToBuy";
import { getAllTickets } from "@/services/ticket.service";

export default {
  name: "TicketShop",
  components: { SingleTicketToBuy },
  data() {
    return {
      tickets: []
    }
  },
  computed:{
  currentUser(){
    return this.$store.state.auth.user;
  }
  },
  created() {
    this.getTickets();
  },
  methods: {
    async getTickets() {
      this.tickets = await getAllTickets();
    }
  }
};
</script>

<style scoped>
.sold-out {
  color: red;
  font-weight: bold;
}
</style>