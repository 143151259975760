<template>
      <CreateFeedItem :show="dialog" @toggleDialog="toggle"/>

</template>

<script>
import CreateFeedItem from "@/components/feed/CreateFeedItem";
export default {
  name: "CreateFeed",
  data(){
    return{
      dialog:true
    }
  },
  components: {CreateFeedItem},
  methods:{
    toggle(value){
      this.dialog=value;
    }
  }
}
</script>

<style scoped>

</style>