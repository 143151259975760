<template>
  <div class="game-container">
    <div
        v-if="current"
        class="fixed rounded fixed--center"
        style="z-index: 3"
        :class="{ 'transition': isVisible }">
      <Vue2InteractDraggable
          v-if="isVisible"
          :interact-out-of-sight-x-coordinate="500"
          :interact-max-rotation="15"
          :interact-x-threshold="10"
          :interact-y-threshold="10"
          interact-lock-swipe-down
          @draggedRight.once="emitAndNext('match')"
          @draggedLeft.once="emitAndNext('reject')"
          @draggedUp.once="emitAndNext('skip')"
          class="rounded-borders game-card game-card--one">
        <div style="height: 100%">
          <img
              :src="current.profilePic?'data:image/png;base64,'+current.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"
              class="rounded-borders"/>
          <div class="game-text">
            <h3>{{current.firstName+' '+current.lastName}} <span>{{current.labelRole}} @{{current.companyName}}</span></h3>
          </div>
        </div>
      </Vue2InteractDraggable>
    </div>
    <div
        v-if="next"
        class="rounded-borders game-card game-card--two fixed fixed--center"
        style="z-index: 2">
      <div style="height: 100%">
        <img
            :src="next.profilePic?'data:image/png;base64,'+next.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"

            class="rounded-borders"/>
        <div class="game-text">
          <h3>{{next.name}} <span>{{current.labelRole}} @{{current.companyName}}</span></h3>
        </div>
      </div>
    </div>
    <div
        v-if="index + 2 < cards.length"
        class="rounded-borders game-card game-card--three fixed fixed--center"
        style="z-index: 1">
      <div style="height: 100%">
      </div>
    </div>
    <div class="footer fixed">

      <v-btn fab @click="reject">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-btn fab @click="skip">
        <v-icon>mdi-call-missed</v-icon>
      </v-btn>
      <v-btn color="accent" fab @click="match">
        <v-icon >mdi-favourite</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { Vue2InteractDraggable } from 'vue2-interact'
import ApiService from "@/services/api.service";

export default {
  name: 'UserGameAlternative',
  components: { Vue2InteractDraggable },
  created() {
    this.getUsers();
  },
  data() {
    return {
      isVisible: true,
      index: 0,
      cards: [

      ]
    }
  },
  computed: {
    current() {
      return this.cards[this.index]
    },
    next() {
      return this.cards[this.index + 1]
    }
  },
  methods: {
    getUsers(){
      let self=this;
      ApiService.get("user/getAll").then(response => {
        self.cards=response.data;

      })
    },
    match() {
      console.log("Match");
    },
    reject() {
      console.log("Reject");
    },
    skip() {
      console.log("Skip");

    },
    emitAndNext(event) {
      this.$emit(event, this.index);
      if(event==='match') this.match();
      else if(event==='reject') this.reject();
      else this.skip();
      setTimeout(() => this.isVisible = false, 200)
      setTimeout(() => {
        this.index++
        this.isVisible = true
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>

.game-container {
  width: 100%;
  height: 100%;
}

.fixed {
  width:100%;
  position: absolute;
  &--center {
    left: 0;
    top: 60px;
    }
}
.game-card {
  width: 100%;
  color: white;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
  .game-text {
    position: absolute;
    bottom: 0;
    width: 100%;
    background:black;
    background:var(--v-primary-base);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    text-indent: 20px;
    span {
      font-weight: normal;
    }
  }
}

.transition {
  animation: appear 200ms ease-in;
}

@keyframes appear {
  from {
    transform: translate(-48%, -48%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}
</style>