<template>
  <v-card class="pa-10">
    <EditUserAdmin @close="toggle(false)" @update="updateUser" :user-id="userId"/>
  </v-card>
</template>

<script>
import EditUserAdmin from "@/components/user/EditUserAdmin";
export default {
  name: "EditUserDialogContent",
  components: {EditUserAdmin},
  data(){
    return{
      userToSave:null
    }
  },
  props:{
    userId:Number
  },
  methods:{
    updateUser(user){
      console.log("User",user);
      this.userToSave=user;
      this.toggle(false);
      this.$emit("update",user);
    },
    toggle(value){
      console.log("toggle")
      this.$emit("toggle",value);
    }
  }

}
</script>

<style scoped>

</style>