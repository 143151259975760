import axios from 'axios';
import host from "./host";
import Notification from "@/models/notification";
const API_URL =host+'/api/auth/';


import store from "../store";

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'signin', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    loginCognito(tokenObject) {
        return axios
            .post(API_URL + 'verifyOAuth', {
                token: tokenObject.token,
                email: tokenObject.extraEmail
            }).catch(function (error) {
                console.log(error.response)
                if (error.response.status === 423) {
                    //TODO Poipup with name to verify and then connect Account
                    console.log("The Email is already in use by a normal Account.");
                    store.dispatch("notificationsStore/add", new Notification("error", "The Email is already in use.", false))
                }
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }


    register(user) {
        return axios.post(API_URL + 'signup', {
            firstName: user.firstName,
            lastName: user.lastName,
            name: user.name,
            email: user.email,
            password: user.password,
            image: user.image
        }).catch(function (error) {
            console.log(error.response)
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status == 401) {
                    store.dispatch("notificationsStore/add", new Notification("error", "You are not logged in.", false, null, "/login", "Login"))
                    store.dispatch("auth/logout");

                } else if (error.response.status == 404) {
                    store.dispatch("loading/error");
                    store.dispatch("notificationsStore/add", new Notification("error", "Name already exists", false))

                } else if (error.response.status == 403) {
                    store.dispatch("loading/error");
                    store.dispatch("notificationsStore/add", new Notification("error", "Email already exists", false))

                } else if (error.response.status == 500 && error.response.data.message) {
                    store.dispatch("loading/error");
                    store.dispatch("notificationsStore/add", new Notification("error", error.response.data.message, false))
                } else if (error.response.data.message) {
                    store.dispatch("loading/error");
                    store.dispatch("notificationsStore/add", new Notification("error", error.response.data.message, false))

                }
            }
        });
    }

    registerFromCart(user, cart) {
        return axios.post(API_URL+'order/signup',{signupRequest: user, cart: cart})
            .catch(function (error) {
                console.log(error.response)
                if (error.response) {
                    if (error.response.data.message) {
                        store.dispatch("loading/error");
                        store.dispatch("notificationsStore/add", new Notification("error", error.response.data.message, false))

                    } else {
                        store.dispatch("loading/error");
                        store.dispatch("notificationsStore/add", new Notification("error", "unknown error", false))
                    }
                }
            });
    }
}
export default new AuthService();
