import ApiService from "@/services/api.service";

export async function getMyTickets(){
    let response = await ApiService.get("ticket/my/all");
    if(response && response.data) return response.data;
}
export async function scanTicket(barcode){
    let response = await ApiService.get("ticket/scan/"+barcode);

     return response;
}
export async function getAllTickets(){
    let response = await ApiService.getUnauthorized("ticket/all");
    return response.data;
}

export async function getBill(cart){
    let order=[];
    for(let cartItem of cart){
        order.push({quantity:cartItem.amount,product:cartItem.product.uid});
    }
    let response = await ApiService.post("ticket/order/bill",order);
    return response.data;
}