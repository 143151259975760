import ApiService from "@/services/api.service";
import Vue from "vue";
import {getMyOrders, updateMyOrdersFromSeeTickets} from "@/services/order.service";

const cartObject = JSON.parse(localStorage.getItem('cart'));
const initialState = cartObject
    ? { cart: cartObject,orders:[]}
    : { cart:[],orders:[] };
export const cart = {
    namespaced: true,
    state:initialState,
    actions:{
        async getOrders({commit}){
            let data= await getMyOrders();
            commit("setOrders",data);
            return data;
        },
        async updateOrders({commit}){
            let data= await updateMyOrdersFromSeeTickets();
            commit("setOrders",data);
            return data;
        },
        async getCart({commit}) {
            let response = await ApiService.get("cart/my");
            if(response.status === 200 ) commit('setCart',response.data);
        },
        addToCartAnonym({commit},product){
            product.amount+=1;
             commit('addToCart',product);
        },
        async addToCart({commit}, product) {
            let response = await ApiService.get("cart/add/" + product.uid);
            if (response.data) {
                commit('addToCart', response.data);
            }

        },
        removeFromCartAnonym({commit},product){
            commit('removeFromCart',product);
        },
        async removeFromCart({commit}, product) {
            let response = await ApiService.get("cart/remove/" + product.product.uid);

            if (response.status===200) {
                commit('removeFromCart', product);
            }
        },
        removeOneAnonym({commit},product){
            product.amount-=1;
            commit('removeOne',product);
        },
        async removeOne({commit}, product) {

            let response = await ApiService.get("cart/amount/" + product.product.uid+"/-1");
            if (response.status === 200) {
                product.amount = response.data;
                commit('removeOne',product);
            }
        },
        addOneAnonym({commit}, product){
            product.amount+=1;
            commit('addOne',product);
        },
        async addOne({commit}, product) {
            console.log("Product",product)
            let response = await ApiService.get("cart/amount/" + product.product.uid+"/1");
            if (response.status === 200) {
                product.amount = response.data;
                commit('addOne',product);
            }
        },
        async buy({commit},cart){
            let order=[];
            for(let cartItem of cart.products){
                order.push({quantity:cartItem.amount,product:cartItem.product.uid});
            }
            let response = await ApiService.post("ticket/order/place",{products:order,payment_method:cart.paymentMethod});

            if(response.status===200){
                //TODO Payment
                console.log("Success",response.data);
                commit("emptyCart")
                let orders = await getMyOrders();
                commit("setOrders",orders);
                return Promise.resolve(response.data);
            }

        }
    },
    mutations:{
        setOrders(state,orders){
          state.orders=orders;
        },
        emptyCart(state){
          state.cart=[];
          localStorage.setItem('cart', JSON.stringify([]));
        },
        setCart(state,cart){
          state.cart=cart;
          localStorage.setItem('cart', JSON.stringify(cart));
        },
        addToCart(state,product){
            let index  = state.cart.findIndex(x => x.product.uid === product.product.uid)
            if(index !=-1){
                Vue.set(state.cart, index, product);
                //state.cart[index].amount+=1;
            }
            else state.cart.push(product);
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        removeFromCart(state,product){
            let index = state.cart.findIndex(x => x.product.uid === product.product.uid);
            console.log(index);
            if(index != -1){
                state.cart.splice(index,1);
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        removeOne(state,product){
            console.log(product)
            let index = state.cart.findIndex(x => x.product.uid === product.product.uid);
            if(index != -1){
                if(state.cart[index].amount>0) Vue.set(state.cart, index, product);

                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        addOne(state,product){
            console.log(product)
            let index = state.cart.findIndex(x => x.product.uid === product.product.uid);
            console.log(index);
            if(index != -1){

                Vue.set(state.cart, index, product);


            }
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        buy(){

        }
    }
}