<template>
<v-sheet class="pa-5" elevation="2" color="secondary">
  <div class="d-flex justify-space-between"><h3 v-text="'Order #'+order.uid.substr(0,10)+'...'"></h3><v-btn :href="order.downloadLink" v-if="completed" plain target="_blank" small><v-icon small>mdi-download</v-icon>Tickets</v-btn></div>
  <h5 v-text="order.status"></h5>
  <v-banner
      v-if="order.status==='pending' && order.paymentLink"
      outlined
      elevation="1"
      class="custom-border-radius"
    icon="mdi-cash-clock"
            single-line
    >You have to pay your order <template #actions>
    <v-btn text :href="order.paymentLink">Pay now</v-btn>

    <PayOrder @toggle="(v) => this.showPayment = v" :show="showPayment" :payment-link="order.paymentLink"/>
  </template>
  </v-banner>

  <v-expansion-panels class="my-5">
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template>
        <v-row no-gutters>
          <v-col cols="4">
            <span v-text="order.tickets.length+ ' Ticket'"></span><span v-if="order.tickets.length>1 || order.tickets.length===0">s</span>
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <SingleTicket v-for="ticket of order.tickets" :key="ticket.uid" :ticket="ticket"/>
    </v-expansion-panel-content>
  </v-expansion-panel>
  </v-expansion-panels>

  <v-divider class="my-2">
  </v-divider>
  <div class="d-flex justify-space-between"><span>Total</span><span v-text="totalPrice"></span></div>
  <div class="d-flex justify-space-between"><span>Service Fee</span><span v-text="serviceFee"></span></div>
  <div class="d-flex justify-space-between"><span>Grand Total</span><span v-text="grandTotal"></span></div>
</v-sheet>
</template>

<script>
import SingleTicket from "@/components/ticket/SingleTicket";
import PayOrder from "@/components/order/payment/PayOrder";
export default {
  name: "OrderItem",
  components: {PayOrder, SingleTicket},
  props:{
    order:Object
  },
  data(){
    return{
      showPayment:false
    }
  },
  computed:{
    totalPrice(){
      let Euro = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
      return Euro.format(this.order.totalPrice.amount);
    },

    completed(){
      return this.order.status==='completed';
    },
    serviceFee(){
      let Euro = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
      return Euro.format(this.order.serviceFee.amount);
    },
    grandTotal(){
      let Euro = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
      return Euro.format(this.order.grandTotal.amount);
    }
  }
}
</script>

<style scoped>

</style>