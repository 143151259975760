import Compressor from "compressorjs";
import store from "@/store";
import Notification from "@/models/notification";
export function compressImage(dataUri){
    return new Compressor(dataUri, {
        quality: 0.6,
        width:720,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
            return result;
        },
        error(err) {
            console.log(err.message);
            store.dispatch("notificationsStore/add",new Notification("error","Fehler beim Komprimieren Ihrer Datei. Bitte verwenden Sie ein anderes Bild"));

        },
    });
}
export function compressImageCustom(dataUri,quality,width){
    return new Compressor(dataUri, {
        quality: quality,
        width:width,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
            return result;
        },
        error(err) {
            console.log(err.message);
            store.dispatch("notificationsStore/add",new Notification("error","Fehler beim Komprimieren Ihrer Datei. Bitte verwenden Sie ein anderes Bild"));

        },
    });
}
export function addMetaToBase64(base64String,fallback){
    if(base64String) {
        return 'data:image/png;base64,' + base64String;
    }else return fallback;
}
export function fileToByteArray(file){
    var reader = new FileReader();
    reader.onload = function() {

        var arrayBuffer = file,
            array = new Uint8Array(arrayBuffer),
            binaryString = String.fromCharCode.apply(null, array);

        console.log(binaryString);

    }
    reader.readAsArrayBuffer(file);
}