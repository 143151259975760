<template>
<v-sheet color="secondary" class="pa-7">
  <h2 class="text-uppercase">CHECK OUT THE LOBBY, <span v-html="currentUser.firstName+' '+currentUser.lastName"></span></h2>
  <v-layout class="mx-0 mx-md-5 px-1" justify-space-between wrap style="max-width: 100%" v-if="users.length>0">

      <v-flex xs12 class="my-2 d-flex justify-center" md5 v-for="user of users" :key="user.id">
        <UserSheet :user="user"></UserSheet>
      </v-flex>

  </v-layout>
</v-sheet>
</template>

<script>
import UserSheet from "@/views/Lobby/UserSheet";
import ApiService from "../../services/api.service";
export default {
  name: "LobbyView",
  components: {UserSheet},
  data(){
    return{
    users:[]
    }
  },
  created() {
    this.getUsers();
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    }
  },
  methods:{
    getUsers(){
      let self=this;
      ApiService.get("user/getAll").then(response => {
        self.users=response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>